import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";

import PredefinedRuleIndex from './predefined/Index';
import PredefinedRuleNew from './predefined/New';
import PredefinedRuleEdit from './predefined/Edit';

import Error404 from '../errors/Error404';
import Error500 from '../errors/Error500';




class PredefinedRules extends React.Component {
  render() {
    return(
      <HashRouter>
        <Error500>
          <Switch>
            <Route exact path="/" component={PredefinedRuleIndex} />
            <Route exact path="/new" component={PredefinedRuleNew} />
            <Route exact path="/edit/:rule_id" component={PredefinedRuleEdit} />
            <Route component={Error404} />
          </Switch>
        </Error500>
      </HashRouter>
    )
  }
}

export default PredefinedRules
