import Statistics from './statistics';

class ResourcesStatistics extends Statistics {
  constructor(ranges) {
    super();

    this.init(gon.date_format, gon.filter_path, gon.filters, gon.csv_path, "#csv_url", "chart", "#data_table");
    this.dateRangeInit(jQuery('#daterange'), ranges);
    this.multiSelectizeInit(jQuery("#service-provider-select"), "service_providers");
    this.multiSelectizeInit(jQuery("#authentication-type-select"), "auth_types");
    this.multiSelectizeInit(jQuery("#attributes-select"), "attributes");
    this.multiSelectizeInit(jQuery("#profiles-select"), "profiles");
    this.multiSelectizeInit(jQuery("#groups-select"), "groups");
    this.multiSelectizeInit(jQuery("#countries-select"), "country_codes");
    this.textInputInit(jQuery("#users-filter"), "users");
    this.confirmQueryUser(gon.profile_path, jQuery("#confirmQueryUser"), jQuery("#users-filter"),jQuery("#confirmQueryUserModal"));
    this.tooltipInit(jQuery("#totals a"));
    this.compareInit("#compare-up", 'up');
    this.compareInit("#compare-down", 'down');
    this.top10Init("#top-10-only");
    this.attributes_andor();
    
    this.chartInit(gon.authentications, "BarChart");
   
  }
}

export default ResourcesStatistics;
