import React from "react";
import PropTypes from "prop-types";
import Error404 from './Error404';
import Error403 from './Error403';

class Error500 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      errorType: 500
    }
  }

  componentDidCatch(error, info) {
    let error_type = 500;
    if (error.message == "404,NotFound") {
      error_type = 404;
    } else if (error.message == "403,Forbidden") {
      error_type = 403;
    } else if (error.message == "500,You are not authenticated.") {
      window.location.href = '/';
      return;
    }

    this.setState({ hasError: true, errorType: error_type  });
  }



  render() {
    if (this.state.hasError) {
      if (this.state.errorType == 404) {
        return (
          <Error404  />
        );
      } else if (this.state.errorType == 403) {
        return (
          <Error403  />
        );
      } else {
        return (
          <div>
            <h1>Internal Server Error </h1>
            <p>An unexpected error has occured.</p>
            <p><a href="/">Click here to go back</a></p>
          </div>
        );
      }
    }

    return this.props.children;
  }
}

export default Error500;
