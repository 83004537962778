import { 
  AddExtraInfoToNavigationItems 
} from "./NavigationHelpers";

import fetchErrorHandler from '../errors/fetchErrorHandler.js';
import fetchJsonResultHandler from '../misc/fetchJsonResultHandler.js';

export const NavigationItems = async() => {

  if (typeof window.navList !== 'undefined' && window.navList.length > 0) {
    return window.navList;
  }

  let navList = [];

  let result = await fetch(
    '/api/internal/navigation.json',
    {credentials: 'same-origin'}
  );

  result = await fetchErrorHandler(result);
  let data = await fetchJsonResultHandler(result);

  if (data.status == "ok") {  
    navList = data.nav_list;
  }

  navList = AddExtraInfoToNavigationItems(navList);

  window.navList = navList;

  return navList;
};
