import * as $ from 'jquery';
import 'datatables.net'
import '../../lib/datatables-bootstrap4';
import 'datatables.net-select';
import 'microplugin';
import 'sifter';

class ScheduledReports {
  constructor() {
    $.fn.dataTable.ext.errMode = function (settings, helpPage, message) {
      if (helpPage == "1" || helpPage == 1) {
        window.location.href = "/";
      }
    };    

    this.initTable();
  }

  initTable() {
    jQuery(gon.datatablesInput).dataTable({
      processing: true,
      serverSide: true,
      ajax: jQuery(gon.datatablesInput).data('source'),
      pagingType: 'full_numbers',
      columns: gon.datatablesColumns,
      aaSorting: [[1, 'desc']],
      order: [[1, 'desc']],
      autoWidth: false,
      columnDefs: [
        {
          targets: 0,
          render: function(data, type, row) {
            return '<span data-pk="'+ data + '"></span>';
          }
        },
        {
          targets: 4,
          render: function(data, type, row){
            data = data.replace(/\r\n/g, "<br />");
            data = data.replace(/;;b;/g, "</strong>");
            data = data.replace(/;b;/g, "<strong>");
            return data;
          }
        },
        {
          targets: 7,
          render: function(data, type, row) {
            return "<a href='/statistics/savedreports/scheduled/edit/" + data +"'><i class='fa fa-edit'></i></a>";
          }
        }
      ],
      select: {
        style:    'multi',
        selector: 'td:first-child'
      }
    });

    jQuery("#confirmDeleteRules").on('click', function() {
      var selectedRules = []
      jQuery(".selected").each(function(index, e) {
        var pk = jQuery(this).find("span").data("pk");
        selectedRules.push(pk);
      });

      $.post('/statistics/savedreports/scheduled/delete.json', {pk: selectedRules}, function (jdata) {
      }).always(function() {
        window.location.href = "/statistics/savedreports/scheduled";
      });

      jQuery("#confirmDeleteRules").modal('hide');
    });
        
  }
};

export default ScheduledReports;
