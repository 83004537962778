import React from "react";
import fetchErrorHandler from '../errors/fetchErrorHandler.js';
import fetchJsonResultHandler from '../misc/fetchJsonResultHandler.js';
import readCookie from '../misc/readCookie.js';
import IdmFormCommon from './FormCommon';

class IdmNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      caughtError: '',
      loading: false,
      username: '',
      email: '',
      first_name: '',
      last_name: '',
      error_message: '',
      form_type: 'new',
      custom_attributes: [],
      custom_attribute_values: {},
      start_date: null,
      end_date: null,
      enabled: true      
    }           

    this.password_input_ref = React.createRef();
    this.confirm_password_input_ref = React.createRef();                           
  }

  async onSubmit(event) {
    event.preventDefault();
    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"))

    this.setState({
      loading: true
    });

    let password = '';
    let confirm_password = '';

    if (this.password_input_ref && this.password_input_ref.current) {
       password = this.password_input_ref.current.value;
    }    

    if (this.confirm_password_input_ref && 
        this.confirm_password_input_ref.current) {
      confirm_password = this.confirm_password_input_ref.current.value;
    }

    let formData = new FormData();
    formData.append("username", this.state.username);
    formData.append("password", password);
    formData.append("password_confirmation", confirm_password);
    formData.append("first_name", this.state.first_name);
    formData.append("last_name", this.state.last_name);
    formData.append("email", this.state.email);
    formData.append('authenticity_token', csrf_token);

    let start_date = null; 
    let end_date = null;

    if (this.state.start_date != null) {
      start_date = this.state.start_date.format("YYYY-MM-DD");
    }

    if (this.state.end_date != null) {
      end_date = this.state.end_date.format("YYYY-MM-DD");
    }

    formData.append('start_date', start_date);
    formData.append('end_date', end_date);
    formData.append('enabled', this.state.enabled);

    for (var key in this.state.custom_attribute_values) {
      formData.append(
        'custom['+key+']', 
        this.state.custom_attribute_values[key]
      );
    }   


    try {
      let result = await fetch(
        '/api/internal/idm/user.json',{
          method: 'POST',
          body: formData,
          credentials: 'same-origin'
        }
      );

      result = await fetchErrorHandler(result);
      const data = await fetchJsonResultHandler(result);
      if (data.status == "ok") {
        window.location.href = "/idm";
      } else {
        this.setState({
          loading: false,
          error_message: data.message,
        });
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }

  setUpperState(state) {
    this.setState(state);
  }

  render() {
    return (
      <div className="bgc-white bd bdrs-3 p-20 mB-20">
        <h4 className="c-grey-900 mB-20">Add a new user</h4>

        <IdmFormCommon 
          formState={this.state}
          onSubmit={this.onSubmit.bind(this)}
          password_input_ref={this.password_input_ref}
          confirm_password_input_ref={this.confirm_password_input_ref}
          setUpperState={this.setUpperState.bind(this)}
          />
      </div>
    )
  }
}

export default IdmNew
