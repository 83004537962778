import React from "react";
import Loading from '../misc/Loading.jsx';
import RenderError from '../misc/RenderError';
import RenderSuccess from '../misc/RenderSuccess';
import Select from 'react-select/creatable';
import { Link } from "react-router-dom";
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';


class IdmForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      start_date_focused: false,
      show_pw_reset_modal: false
    }
  }

  handlePwResetModalOpen(event) {
    event.preventDefault();
    this.setState({
      show_pw_reset_modal: true,
    });
  }

  handlePwResetModalClose() {
    this.setState({
      show_pw_reset_modal: false,
    });
  }

  handlePwResetSend(event) {
    event.preventDefault();

    this.setState({
      show_pw_reset_modal: false,
    });

    if (this.props.formState.form_type == 'edit') {
      this.props.sendPwReset();
    }
  }

  renderCustomTextBox(attribute) {
    let value = this.props.formState.custom_attribute_values[attribute.name];

    return (
      <input type="text" className="form-control" 
             value={value}
             onChange={
               (e) => this.props.handleChangeCustomAttribute(
                 attribute.name,
                 e.target.value 
               )
             } />
    )
  }

  renderCustomAttribute(attribute, index) {
    let field = this.renderCustomTextBox(attribute);

    return (
      <div className="form-group" key={index}>
        <label><h6>{attribute.label}</h6></label>
        {field}
      </div>
    )
  }

  renderCustomAttributes() {
    let customAttributes = this.props.formState.custom_attributes;

    return (
      <React.Fragment>
        {customAttributes.map(function(value, index) {
          return this.renderCustomAttribute(value, index);
        }.bind(this))}
      </React.Fragment>
    )
  }

  renderPwResetButton() {
    return (
      <React.Fragment>
        &nbsp; <button className="btn btn-secondary" 
                       onClick={this.handlePwResetModalOpen.bind(this)}>
          Send pw reset e-mail
        </button>
      </React.Fragment>
    )
  }

  render() {    
    if (this.props.formState.caughtError) {
      throw this.props.formState.caughtError;
    }

    if (this.props.formState.loading || this.props.additionalState.loading) {
      return (
        <div className="col-sm-12 react-loader">
          <Loading loading={this.props.formState.loading} />
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-sm-12">
            <RenderError error_message={this.props.formState.error_message} />
            <RenderSuccess 
              success_message={this.props.formState.success_message}
            />
            <form onSubmit={this.props.onSubmit}>
              <div className="form-group">
                <label><h6>Username</h6></label>
                <input type="text" id="username"
                       onChange={this.props.handleChange} 
                       value={this.props.formState.username}
                       className="form-control" required={true} />
              </div>

              <div className="form-group">
                <label><h6>First name</h6></label>
                <input type="text" id="first_name"
                       onChange={this.props.handleChange} 
                       value={this.props.formState.first_name}
                       className="form-control" required={false} />
              </div>              

              <div className="form-group">
                <label><h6>Last name</h6></label>
                <input type="text" id="last_name"
                       onChange={this.props.handleChange} 
                       value={this.props.formState.last_name}
                       className="form-control" required={false} />
              </div>            

              <div className="form-group">
                <label><h6>E-mail address</h6></label>
                <input type="text" id="email"
                       onChange={this.props.handleChange} 
                       value={this.props.formState.email}
                       className="form-control" required={true} />
              </div>  
              <hr />
              <div className="form-group">
                <label>
                  <h6>Password</h6>
                  <em>{
                      (this.props.formState.form_type == 'edit') ? 
                        '(leave blank for no change)' : '(leave blank to ' +
                        'send the user an activation email instead)'
                    }
                  </em>
                </label>

                <input type="password" id="password"
                       className="form-control"
                       ref={this.props.password_input_ref}
                       autoComplete="new-password" />
              </div>  

              <div className="form-group">
                <label><h6>Password confirmation</h6></label>
                <input type="password" id="password_confirmation"
                       className="form-control" 
                       ref={this.props.confirm_password_input_ref}
                       autoComplete="new-password" />
              </div>  
              <hr />
              {this.renderCustomAttributes()}

              <hr />
              <div className="form-group">
                <label><h6>Start date</h6></label>
                <SingleDatePicker date={
                   this.props.formState.start_date
                  }
                  onDateChange={
                    date => this.props.setUpperState({ start_date: date })
                  }
                  focused={this.state.start_date_focused}
                  onFocusChange={
                    ({ focused }) => this.setState(
                      { start_date_focused: focused }
                    )
                  }
                  id="start_date" 
                  openDirection="up"
                  displayFormat="DD/MM/YYYY" />

              </div>

              <div className="form-group">
                <label><h6>End date</h6></label>

                <SingleDatePicker date={
                   this.props.formState.end_date
                  }
                  onDateChange={
                    date => this.props.setUpperState({ end_date: date })
                  }
                  focused={this.state.end_date_focused}
                  onFocusChange={
                    ({ focused }) => this.setState(
                      { end_date_focused: focused }
                    )
                  }
                  id="end_date" 
                  openDirection="up"
                  displayFormat="DD/MM/YYYY" />


              </div>              
              <hr />

              <div className="form-group">

                <div className="form-check">
                  <input type="checkbox" className="form-check-input"
                     onChange={this.props.handleChange}
                     checked={this.props.formState.enabled} 
                     className="form-check-input"
                     id="enabled"
                     />
                    <label><h6>User enabled</h6></label>
                </div>
              </div>

              <p>
               <input type="submit" className="btn btn-primary" value="Save"/>
               {(this.props.formState.form_type == 'edit') ? 
                 this.renderPwResetButton() : ''
               }
               &nbsp;
              <Link to="/" className="btn btn-default">
                Go back
              </Link>     
             </p>                                    
            </form>
          </div>
        </div>

        <Modal show={this.state.show_pw_reset_modal} 
               onHide={this.handlePwResetModalClose.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>Password reset</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            Are you sure that you wish to send the user a password reset email?
          </Modal.Body>

          <Modal.Footer>
            <button className="btn btn-secondary"
                    onClick={this.handlePwResetModalClose.bind(this)}>
                    Close
            </button>
            <button className="btn btn-primary"
                    onClick={this.handlePwResetSend.bind(this)}>Confirm</button>
          </Modal.Footer>
        </Modal>           
      </React.Fragment>
    )
  }  
}

export default IdmForm
