import React from "react";
import PropTypes from "prop-types";

class Error404 extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
    return (
      <div>
        <h1>Page not found</h1>
        <p>
          The page that you are looking for may have been removed or renamed.
        </p>
        <p><a href="/">Click here to go back</a></p>
      </div>
    )
  }
}

export default Error404
