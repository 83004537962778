import React from "react";
import Loading from '../../misc/Loading.jsx';
import RenderError from '../../misc/RenderError';
import Select from 'react-select/creatable';
import AsyncSelect from 'react-select/async-creatable';
import { Link } from "react-router-dom";
import RegularSelect from 'react-select';

class PredefinedRuleForm extends React.Component {
  constructor(props) {
    super(props);

  }

  renderAttributeValInput(id, type) {
    let value = this.props.formState.attributeInputValues[type][id];

    if (typeof value === 'object') {
      value = '';
    }

    return (
      <input type="text" className="form-control" 
             onChange={(e) => 
              this.props.onAllowDenyInputChange(e.target.value, id, type)
             }
            value={value}/>
    )
  }

  renderAttributeRegexInput(id, type) {
    let regex_value = this.props.formState.attributeRegexInputValues[type][id];
    let multi_value = this.props.formState.attributeMultiValueInputValues[type][id];

    const options = [
      { value: 'single', label: 'Single value' },
      { value: 'multi_value', label: 'Multi value' },
      { value: 'regex', label: 'Regex' },
    ]

    let select_value = { value: 'single', label: 'Single value' };

    if (multi_value) {
      select_value = { value: 'multi_value', label: 'Multi value' };
    } else if (regex_value) {
      select_value = { value: 'regex', label: 'Regex' };
    }


    return (
      <React.Fragment>
        <div className="gran_actions_select_box">
          <RegularSelect
            options={options}
            isClearable={false}
            isSearchable={false}
            isMulti={false}
            value={select_value}
            onChange={(e) => 
              this.props.onAllowDenyValueTypeSelectChange(e, id, type)
            }
          />
        </div>
      </React.Fragment>
    )
  }

  renderBox(id, type='allow') {
    let attributeSelectValue = this.props.formState.attributeSelectValues[type][id];
    let attributeInputDom = this.renderAttributeValInput(id, type);
    let attributeRegexInputDom = this.renderAttributeRegexInput(id, type);
    let regexEnabled = this.props.formState.attributeRegexInputValues[type][id];
    let multiValue = this.props.formState.attributeMultiValueInputValues[type][id];

    if (attributeSelectValue != null && regexEnabled != true) {
      if (attributeSelectValue.value == "sAMAccountName") {
        attributeInputDom = this.renderUsernameSearchBox(id, type, multiValue);
      } else if (attributeSelectValue.value == 'groups') {
        attributeInputDom = this.renderGroupSearchBox(id, type, multiValue);
      }
    }


    return (
      <div className="gran-select-box" key={id}>
        <div className="gran_select">
          <Select 
            options={this.props.formState.attributeOptions}
            isClearable={true}
            isSearchable={true} 
            onChange={(e) => 
              this.props.onAllowDenySelectChange(e, id, type)
             }
            value={attributeSelectValue}
            />
        </div>
        <div className="gran_input">
          {attributeInputDom}
        </div>

        <div className="gran_actions">
          {attributeRegexInputDom}
          <button className="btn btn-sm btn-danger"
                  onClick={(e) => this.props.removeBox(e, id, type)}>
            <i className="fa fa-times"></i>
          </button>
        </div>

        <div className="gran_clear" />
      </div>  
    )
  }

  renderAllowBoxes() {
    let allow_boxes = [];
    for (var i=0; i< this.props.formState.allow_attribute_boxes; i++) {
      allow_boxes.push(i)
    }

    return (
      <React.Fragment>
        {allow_boxes.map(function(value, index) {
          return this.renderBox(value, 'allow');
        }.bind(this))}
      </React.Fragment>
    )
  }

  renderDenyBoxes() {
    let deny_boxes = [];
    for (var i=0; i< this.props.formState.deny_attribute_boxes; i++) {
      deny_boxes.push(i)
    }

    return (
      <React.Fragment>
        {deny_boxes.map(function(value, index) {
          return this.renderBox(value, 'deny');
        }.bind(this))}
      </React.Fragment>
    )
  }

  promiseUsernameOptions = inputValue => {
    return new Promise(resolve => {
      resolve(this.props.searchUsername(inputValue))
    })
  }

  renderUsernameSearchBox(id, type, multiValue) {
    let value = this.props.formState.attributeInputValues[type][id];

    let values = null;
    if (typeof value === 'object') {
      value = null;
    } else if (multiValue) {
      values = value.split(",").map(function(value, index) {
        return {value: value, label: value}
      }).filter(value => value.value != null && value.value != '');
    } else {
      values = {value: value, label: value};
    }

    return (
      <AsyncSelect 
        cacheOptions={true}
        isClearable={true}
        isSearchable={true} 
        isMulti={multiValue}
        loadOptions={this.promiseUsernameOptions}
        value={values}
        onChange={(val) => this.props.handleUsernameSelectChange(
          id, type, val, multiValue
         )}
        />
    )    
  }


  promiseGroupOptions = inputValue => {
    return new Promise(resolve => {
      resolve(this.props.searchGroup(inputValue))
    })
  }

  renderGroupSearchBox(id, type, multiValue) {
    let value = this.props.formState.attributeInputValues[type][id];

    let values = null;
    if (typeof value === 'object') {
      value = null;
    } else if (multiValue) {
      values = value.split(",").map(function(value, index) {
        return {value: value, label: value}
      }).filter(value => value.value != null && value.value != '');
    } else {
      values = {value: value, label: value};
    }


    return (
      <AsyncSelect 
        cacheOptions={true}
        isClearable={true}
        isSearchable={true} 
        isMulti={multiValue}
        loadOptions={this.promiseGroupOptions}
        value={values}
        onChange={(val) => this.props.handleGroupSelectChange(
          id, type, val, multiValue
         )}
        />
    )    
  }

  render() {
    if (this.props.formState.caughtError) {
      throw this.props.formState.caughtError;
    }

    if (this.props.formState.loading) {
      return (
        <div className="col-sm-12 react-loader">
          <Loading loading={this.props.formState.loading} />
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-sm-12 granularaccess_form">
            <RenderError error_message={this.props.formState.error_message} />
            <form onSubmit={this.props.onSubmit}>
              <div className="form-group">
                <label><h6>Predefined condition set name</h6></label>
                <input type="text" id="rule_name" 
                       className="form-control" required={true}
                       onChange={this.props.handleChange}
                       value={this.props.formState.rule_name} />
              </div>

              <div className="form-group">
                <label><h6>Comments</h6></label>
                <textarea id="comments" className="form-control"
                          onChange={this.props.handleChange}
                          value={this.props.formState.comments} />
              </div>
             <hr />

             <div className="form-group">
                <h6>Allow conditions</h6>

                <div className="allow_group">
                  <div className="group_background" />
                  {this.renderAllowBoxes()}
                
                  <button type="button" 
                          className="btn btn-success gran-add-btn"
                          onClick={this.props.onAddAllowCondition}>
                    Add new allow condition
                  </button>
                </div>
              </div>
              <hr />
              <div className="form-group">
                <h6>Deny conditions</h6>
          
                <div className="deny_group">
                  <div className="group_background" />
                  {this.renderDenyBoxes()}

                  <button 
                          type="button"
                          className="btn btn-success gran-add-btn"
                          onClick={this.props.onAddDenyCondition}>
                    Add new deny condition
                  </button>
                </div>
              </div>
            
             <p>
               <input type="submit" className="btn btn-primary" value="Save"/>
               &nbsp;
              <Link to="/predefined" className="btn btn-default">
                Go back
              </Link>     
             </p>                       
            </form>
          </div>
        </div>
      </React.Fragment>
    )
  }  
}

export default PredefinedRuleForm
