import * as $ from 'jquery';
import 'datatables.net'
import '../../lib/datatables-bootstrap4';
import 'datatables.net-select';
import 'microplugin';
import 'sifter';

class DeviceAgents {
  constructor() {
    $.fn.dataTable.ext.errMode = function (settings, helpPage, message) {
      if (helpPage == "1" || helpPage == 1) {
        window.location.href = "/";
      }
    };    

    this.initTable();
  }

  initTable() {
    if (gon.datatablesInput == "#device_agent") {
      jQuery(gon.datatablesInput).dataTable({
        processing: true,
        serverSide: true,
        ajax: jQuery(gon.datatablesInput).data('source'),
        pagingType: 'full_numbers',
        columns: gon.datatablesColumns,
        aaSorting: [[0, 'asc']],
        order: [[0, 'asc']],
        columnDefs: [
          {
            targets: 4,
            render: function(data, type, row) {
              return "<a href='/configuration/deviceagent/delete/" + data +"' class='btn btn-danger'><i class='fa fa-times'></i></a>";
            }
          }
        ],
      });
    } else {
      jQuery(gon.datatablesInput).dataTable({
        processing: true,
        serverSide: true,
        ajax: jQuery(gon.datatablesInput).data('source'),
        pagingType: 'full_numbers',
        columns: gon.datatablesColumns,
        aaSorting: [[0, 'asc']],
        order: [[0, 'asc']],
        columnDefs: [
          {
            targets: 4,
            render: function(data, type, row) {
              return "<a href='/configuration/deviceagent/unapproved/approve/" + data +"' class='btn btn-success'><i class='fa fa-check'></i></a> <a href='/configuration/deviceagent/unapproved/delete/" + data +"' class='btn btn-danger'><i class='fa fa-times'></i></a>";
            }
          }
        ],
      });
    }
  }
};

export default DeviceAgents;
