import * as $ from 'jquery';
import 'leaflet';

class HeatMap {
  constructor(map_id, geodata) {
    this.state = {
      map_id: map_id,
      map: null,
      map_geolayer: null,
      map_legendlayer: null,
      map_geodata: geodata
    }

    this.init_map();
  }

  init_map() {
    var self = this;

    var baseLayer = L.tileLayer(
      'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',{
        attribution: 'Map data &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="http://cloudmade.com">CloudMade</a>',
        maxZoom: 4,
        minZoom: 2
      }
    );

    self.state.map = new L.Map(self.state.map_id, {
      center: new L.LatLng(54, -2),
      zoom: 3,
      layers: [baseLayer]
    });

    var southWest = L.latLng(-89.98155760646617, -180), northEast = L.latLng(89.99346179538875, 180);
    var bounds = L.latLngBounds(southWest, northEast);
    self.state.map.setMaxBounds(bounds);

    self.state.map.on('drag', function() {
      self.state.map.panInsideBounds(bounds, { animate: false });
    });
  }

  update_map(data, max) {
    var self = this;


    function getMapSteps(step) {
      if (max > 1000000) {
        return (step * 1000);
      } else if (max > 100000) {
        return (step * 100);
      } else if (max > 10000) {
        return (step * 10);
      } else if (max > 1000) {
        return step;
      } else {
        return step / 5;
      }
    }

    function getMapColor(d) {
      return d > getMapSteps(1000) ? '#800026' :
          d > getMapSteps(500)  ? '#BD0026' :
          d > getMapSteps(200)  ? '#E31A1C' :
          d > getMapSteps(100)  ? '#FC4E2A' :
          d > getMapSteps(50)   ? '#FD8D3C' :
          d > getMapSteps(20)   ? '#FEB24C' :
          d > getMapSteps(10)   ? '#FED976' : '#FFEDA0';
    }

    function getMapOpacity(d) {
      if (d > 0) {
        return 0.7;
      } else {
        return 0;
      }
    }

    function addFeaturePopups(feature ,layer) {
      if (data[feature.id] && data[feature.id] > 0) {
        layer.bindPopup(data[feature.id] + " authentications");
      }
    }

    function styleMap(feature) {
      return {
        weight: 0,
        opacity: 1,
        color: 'white',
        fillOpacity: getMapOpacity(data[feature.id]),
        fillColor: getMapColor(data[feature.id])
      }
    }

    // Remove existing layers if they exist
    if (self.state.map_geolayer) {
      self.state.map.removeLayer(self.state.map_geolayer);
    }

    if (self.state.map_legendlayer) {
      self.state.map.removeLayer(self.state.map_legendlayer);
      jQuery("#" + self.state.map_id).find(".heatmap_legend").remove();
    }

    // Create map legend
    self.state.map_legendlayer = L.control({position: 'bottomright'});

    self.state.map_legendlayer.onAdd = function (map) {

      var div = L.DomUtil.create('div', 'heatmap_info heatmap_legend'),
        grades = [0, 10, 20, 50, 100, 200, 500, 1000],
        labels = [],
        from, to;

      for (var i = 0; i < grades.length; i++) {
        from = getMapSteps(grades[i]);
        to = getMapSteps(grades[i + 1]);

        labels.push(
          '<i style="background:' + getMapColor(from + 1) + '"></i> ' +
          from + (to ? '&ndash;' + to : '+'));
      }

      div.innerHTML = labels.join('<br>');
      return div;
    };


    // Create map geo polygons
    self.state.map_geolayer = L.geoJSON(this.state.map_geodata, {style: styleMap, onEachFeature: addFeaturePopups});
    self.state.map_geolayer.addTo(this.state.map);
    self.state.map_legendlayer.addTo(this.state.map);
  }
};

export default HeatMap;
