import React from "react";
import fetchErrorHandler from '../errors/fetchErrorHandler.js';
import fetchJsonResultHandler from '../misc/fetchJsonResultHandler.js';
import readCookie from '../misc/readCookie.js';
import GranularAccessFormCommon from './FormCommon';

class GranularAccessEditRule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      caughtError: '',
      loading: true,
      allow_attribute_boxes: 0,
      deny_attribute_boxes: 0,
      predefined_boxes: 0,
      attributeOptions: [],
      entityIds: [],
      enttiyIdSelect: null,
      sps: [],
      attributeSelectValues: {
        allow: {},
        deny: {}
      },
      attributeInputValues: {
        allow: {},
        deny: {},
        predefined: {},
      },
      attributeRegexInputValues: {
        allow: {},
        deny: {},
      },       
      attributeMultiValueInputValues: {
        allow: {},
        deny: {}
      },      
      predefined_rules: [],
      predefined_values: {
        predefined_allow: {},
        predefined_deny: {}
      },
      rule_name: '',
      comments: '',
      error_message: '',
      success_message: '',
      allow_radio: 'AND',
      deny_radio: 'AND',
      mfaStepUp: 'allow',
      defaultAction: 'allow',
    }
  }


  async onSubmit(event) {
    event.preventDefault();
    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"))

    this.setState({
      loading: true
    });

    let formData = new FormData();
    formData.append('rule_id', this.props.match.params.rule_id);
    formData.append("rule_name", this.state.rule_name);
    formData.append("comments", this.state.comments);

    for(var key in this.state.sps) {
      formData.append("entity_ids[]", this.state.sps[key]);
    }

    for (var key in this.state.attributeSelectValues.allow) {
      if (this.state.attributeSelectValues.allow[key] == null) {
        this.state.attributeSelectValues.allow[key] = {
          value: null
        }
      }
      formData.append( 
        'allow_attributes[]', 
         this.state.attributeSelectValues.allow[key].value
      );
    }
    for (var key in this.state.attributeInputValues.allow) {
      let value = this.state.attributeInputValues.allow[key];

      if (typeof value === 'object') {
        if (typeof value.value !== 'undefined') {
          value = value.value;
        }
      }

      formData.append(
        'allow_values[]', 
        value  
      );
    }

    for (var key in this.state.attributeRegexInputValues.allow) {
      let value = this.state.attributeRegexInputValues.allow[key];

      if (typeof value === 'object') {
        if (typeof value.value !== 'undefined') {
          value = value.value;
        }
      }

      formData.append(
        'allow_regex[]', 
        value  
      );
    }    

    for (var key in this.state.attributeMultiValueInputValues.allow) {
      let value = this.state.attributeMultiValueInputValues.allow[key];

      if (typeof value === 'object') {
        if (typeof value.value !== 'undefined') {
          value = value.value;
        }
      }

      formData.append(
        'allow_multi[]', 
        value  
      );
    }   

    for (var key in this.state.attributeSelectValues.deny) {
      if (this.state.attributeSelectValues.deny[key] == null) {
        this.state.attributeSelectValues.deny[key] = {
          value: null
        }
      }      
      formData.append( 
        'deny_attributes[]', 
        this.state.attributeSelectValues.deny[key].value
      );

    }
    for (var key in this.state.attributeInputValues.deny) {
      let value = this.state.attributeInputValues.deny[key];

      if (typeof value === 'object') {
        if (typeof value.value !== 'undefined') {
          value = value.value;
        }
      }

      formData.append(
        'deny_values[]', 
        value
      );
    }    

    for (var key in this.state.attributeRegexInputValues.deny) {
      let value = this.state.attributeRegexInputValues.deny[key];

      if (typeof value === 'object') {
        if (typeof value.value !== 'undefined') {
          value = value.value;
        }
      }

      formData.append(
        'deny_regex[]', 
        value  
      );
    }    

    for (var key in this.state.attributeMultiValueInputValues.deny) {
      let value = this.state.attributeMultiValueInputValues.deny[key];

      if (typeof value === 'object') {
        if (typeof value.value !== 'undefined') {
          value = value.value;
        }
      }

      formData.append(
        'deny_multi[]', 
        value  
      );
    }

    for (var key in this.state.attributeInputValues.predefined) {
      formData.append(
        'predefined_rules[]', 
        this.state.attributeInputValues.predefined[key].value
      );
    }   

    formData.append('allow_radio', this.state.allow_radio);
    formData.append('deny_radio', this.state.deny_radio);
    formData.append('mfa_stepup', this.state.mfaStepUp);
    formData.append('authenticity_token', csrf_token);

    try {
      let result = await fetch(
        '/configuration/granularaccess/edit.json',{
          method: 'POST',
          body: formData,
          credentials: 'same-origin'
        }
      );

      result = await fetchErrorHandler(result);
      const data = await fetchJsonResultHandler(result);
      if (data.status == "ok") {
        this.setState({
          loading: false,
          error_message: '',
          success_message: "The rule has been successfully updated.",
        });
        let lbSyncDiv = document.getElementById('loadbalance_sync_warning');
        lbSyncDiv.style.display = '';          
      } else {
        this.setState({
          loading: false,
          error_message: data.message,
          success_message: ''
        });
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }

  async componentDidMount() {
    try {
      let result = await fetch(
        '/api/internal/configuration/granularaccess/' + 
         this.props.match.params.rule_id, 
        {credentials: 'same-origin'}
      );

      result = await fetchErrorHandler(result);
      let data = await fetchJsonResultHandler(result);

      if (data.status == "ok") {
        let predefinedRules = data.data.predefined_rules;
        let attributeOptions = data.data.attributes.map(x => {
            return {'value': x, 'label': x}
          }
        );
   

        this.setState({
          loading: false,
          attributeOptions: attributeOptions,
          predefined_rules: predefinedRules,
          entityIds: data.data.sps,
          attributeSelectValues: data.data.attribute_select_values,
          attributeInputValues: data.data.attribute_input_values,
          attributeRegexInputValues: data.data.attribute_input_regex_values,
          attributeMultiValueInputValues: data.data.attribute_input_multi_values,
          allow_attribute_boxes: data.data.attribute_input_values.allow.length,
          deny_attribute_boxes: data.data.attribute_input_values.deny.length,
          predefined_boxes: data.data.attribute_input_values.predefined.length,
          predefined_values: data.data.predefined_values,
          rule_name: data.data.rule_name,
          comments: data.data.comments,
          enttiyIdSelect: data.data.entity_ids,
          sps: data.data.entity_ids.map(function(x, index) {
            return x['value'];
          }),
          allow_radio: data.data.allow_radio,
          deny_radio: data.data.deny_radio,
          mfaStepUp: data.data.mfa_stepup,
          defaultAction: data.data.default_action
        });
      }
      
    } catch (error) {
      this.setState({caughtError: error});
    }
  }

  setUpperState(state) {
    this.setState(state);
  }

  render() {
    return (
      <div className="bgc-white bd bdrs-3 p-20 mB-20">
        <h4 className="c-grey-900 mB-20">Edit granular access rule</h4>
        <GranularAccessFormCommon
            formState={this.state}
            onSubmit={this.onSubmit.bind(this)}
            setUpperState={this.setUpperState.bind(this)}
          />
      </div>
    )
  }
}

export default GranularAccessEditRule
