import * as $ from 'jquery';
import '../../lib/bootstrap4-editable';
import Statistics from '../statistics/statistics';

class Loadbalancesync {
  constructor(sync_link, sync_modal, sync_tail_box) {
    this.state = {
      sync_link: sync_link,
      sync_modal: sync_modal,
      sync_tail_box: sync_tail_box
    }

    var self=this;

    if (jQuery(sync_link).length > 0) {
      jQuery(sync_link).on('click', function() {
        self.sync(self);
      });
    }

    if (typeof gon !== 'undefined' && gon.statisticsHelperPage) {
      if (gon.statisticsHelperPage == "statistics_settings") {
        $.fn.editable.defaults.ajaxOptions = { headers: { 'X-CSRF-Token': jQuery('meta[name="csrf-token"]').attr('content') } }

        jQuery(".sp_name_editable").editable({
          success: function(response, newValue) {
            if (jQuery("#loadbalance_sync_warning").length > 0) {
              jQuery("#loadbalance_sync_warning").show();
            }
            if(response.status == 'error') return response.message;
          }
        });        
      }
    }
  }

  sync(self) {
    $.getJSON('/dashboard/loadbalance_sync.json', function(jData) {
      if (!jData) {
        window.location.href = "/dashboard/loadbalance_sync";
        return;
      } else if (jData['status'] == 'ok') {
        jQuery("#loadbalance_sync_warning").hide();
        jQuery(self.state.sync_modal).modal('show');
        self.taillog(self);
      } else {
        window.location.href = "/dashboard/loadbalance_sync";
      }
    }).fail(function() {
      window.location.href = "/dashboard/loadbalance_sync";
    });
  }

  taillog(self) {
    var last_refreshed = "1990-01-01";
    var timer=null;

    function timerTailFunction() {
      $.getJSON("/diagnostics/logviewer.json?log_file_selector=sync_log" + "&tail_modified=" + last_refreshed, function(data) {
        if (data['status'] == "ok") {
          if (last_refreshed != data['last_refreshed']) {
            last_refreshed = data['last_refreshed'];

            $.getJSON("/diagnostics/logviewer.json?log_file_selector=sync_log&raw_file=1", function(jData) {
              if (data['status'] == "ok") {
                jQuery(self.state.sync_tail_box).val(jData['data']);
                jQuery(self.state.sync_tail_box).scrollTop(jQuery(self.state.sync_tail_box)[0].scrollHeight);
                last_refreshed = jData['last_refreshed'];
              } else {
                window.location.href = "/diagnostics/logviewer?log_file_selector=sync_log";
              }
            }).fail(function() {
              window.location.href = "/diagnostics/logviewer?log_file_selector=sync_log";
            });          
          }
        } else {
          window.location.href = "/diagnostics/logviewer?log_file_selector=sync_log";
        }
      }).fail(function() {
        window.location.href = "/diagnostics/logviewer?log_file_selector=sync_log";
      });  
    }

    timer = setInterval(timerTailFunction, 1000);  

    jQuery(self.state.sync_modal).on('hide.bs.modal', function(e) {
      clearTimeout(timer);
      jQuery(self.state.sync_tail_box).val("");
    });
  }
}

export default Loadbalancesync;
