import * as $ from 'jquery';
import 'datatables.net'
import '../../lib/datatables-bootstrap4';
import 'datatables.net-select';
import 'microplugin';
import 'sifter';

class SavedReports {
  constructor() {
    $.fn.dataTable.ext.errMode = function (settings, helpPage, message) {
      if (helpPage == "1" || helpPage == 1) {
        window.location.href = "/";
      }
    };    

    this.initTable();
  }

  initTable() {
    jQuery(gon.datatablesInput).dataTable({
      processing: true,
      serverSide: true,
      ajax: jQuery(gon.datatablesInput).data('source'),
      pagingType: 'full_numbers',
      columns: gon.datatablesColumns,
      aaSorting: [[1, 'desc']],
      order: [[1, 'desc']],
      autoWidth: false,
      columnDefs: [
        {
          targets: 0,
          render: function(data, type, row) {
            return '<span data-pk="'+ data + '"></span>';
          }
        },
        {
          targets: 2,
          render: function(data, type, row){
            data = data.replace(/\r\n/g, "<br />");
            data = data.replace(/;;b;/g, "</strong>");
            data = data.replace(/;b;/g, "<strong>");
            return data;
          }
        },
        {
          targets: 4,
          render: function(data, type, row) {
            var type = 'secondary';

            if (data == 'completed') {
              type = 'success';
            } else if (data == 'failed') {
              type = 'danger';
            } else if (data == 'running') {
              type = 'primary';
            }

            return "<span class=\"badge badge-"+type+"\">" + data + "</div>";
          }
        },
        {
          targets: 5,
          render: function(data, type, row) {
            if (row['status'] == 'completed') {
              return "<a href='/statistics/savedreports/" + data + ".csv'><i class='fas fa-download'></i></a>";
            } else {
              return null;
            }
          }
        }
      ],
      select: {
        style:    'multi',
        selector: 'td:first-child'
      }
    });

    jQuery("#confirmDeleteRules").on('click', function() {
      var selectedRules = []
      jQuery(".selected").each(function(index, e) {
        var pk = jQuery(this).find("span").data("pk");
        selectedRules.push(pk);
      });

      $.post('/statistics/savedreports/delete.json', {pk: selectedRules}, function (jdata) {
      }).always(function() {
        window.location.href = "/statistics/savedreports";
      });

      jQuery("#confirmDeleteRules").modal('hide');
    });
  }
};

export default SavedReports;
