import React from "react";
import PropTypes from 'prop-types';
import fetchErrorHandler from '../errors/fetchErrorHandler';
import Moment from 'react-moment';
import {OverlayTrigger, Popover, Tooltip} from 'react-bootstrap';
import Loading from './Loading.jsx';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';

const DataTableRow = styled.tr`
  /* stylelint-disable comment-empty-line-before */
  ${props =>
    props.snapshot.isDragging
      ? `
    /* maintain cell width while dragging */
    display: table;
  `
    : ''} /* stylelint-enable */;
`;

class DataTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      caughtError: false,
      loading: true,
      rows: [],
      page: 1,
      total_pages: 1,
      total_records: 1,
      per_page: (props.per_page > 0) ? props.per_page : 10,
      search: '',
      order_col: (props.default_order_col.length > 0) ? 
                  props.default_order_col : '',
      order_direction: (props.default_order_dir.length > 0) ? 
                  props.default_order_dir : 'asc',
      all_selected: false,
      checked_ids: [],
      row_ids: []
    }


    this.loadingCallback = this.loadingCallback.bind(this);
    this.loadedCallback = this.loadedCallback.bind(this);
    this.selectedRowCallback = this.selectedRowCallback.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onDragEndCallback = this.onDragEndCallback.bind(this);
    this.onEnableDisableToggle = this.onEnableDisableToggle.bind(this);
    this.onEnableDisableCallback = this.onEnableDisableCallback.bind(this);
  }

  switchPage(page, e=null) {
    this.setState({page: page},() => {
      this.updateData();
    });
    if (e) {
      e.preventDefault();
    }
  }

  handleSearchChange(self, event) {
    let value = event.target.value;

    if (value) {
      self.setState({search: value}, () => {
        self.updateData();
      });
    } else {
      self.setState({search: ''}, () => {
        self.updateData();
      });
    }
  }

  handleColSort(self, event, column) {
    if (!column['sorting']) {
      return;
    }

    if (self.state.order_col == column['data']) {
      let new_direction = 'desc';

      if (self.state.order_direction == 'desc') {
        new_direction = 'asc';
      }

      self.setState({order_direction: new_direction}, () =>  {
        self.updateData();
      });
    } else {
      self.setState({order_col: column['data']}, () =>  {
        self.updateData();
      });
    }
  }

  handleSelectAllChange(self, event) {
    const target = event.target;
    const value = target.checked;

    self.setState({
      all_selected: value
    });

    self.selectedRowCallback(self.state.row_ids);
  }

  handleSelectChange(self, event, row_id) {
    const target = event.target;
    const value = target.checked;

    let checked_ids = this.state.checked_ids;
    let all_selected = this.state.all_selected;

    if (value) {
      if (!checked_ids.includes(row_id)) {
        checked_ids.push(row_id);
      }
    } else {
      if (checked_ids.includes(row_id)) {
        let index = checked_ids.indexOf(row_id);
        if (index > -1) {
          checked_ids.splice(index, 1);
        }
      }
    }

    all_selected = false;

    if (this.state.row_ids.length > 0) {
      if (this.state.row_ids.length === this.state.checked_ids.length) {
        all_selected = true;
      }
    }

    self.setState({
      checked_ids: checked_ids,
      all_selected: all_selected
    });

    self.selectedRowCallback(checked_ids);
  }

  onDragEnd(result) {
    // dropped outside the list
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    // no movement
    if (result.destination.index === result.source.index) {
      return;
    }

    let rows = this.state.rows;
    let sourceRow = rows[result.source.index];
    let destinationRow = rows[result.destination.index];

    // Remove the source
    rows.splice(result.source.index, 1);

    // Insert into new position
    rows.splice(result.destination.index, 0, sourceRow);

    this.setState({
       rows: rows
    });

    this.onDragEndCallback(rows);
  }

  async onDragEndCallback(rows) {
    if (typeof this.props.onDragEndCallback !== 'undefined') {
      if (await this.props.onDragEndCallback(rows)) {
        this.updateData();
      }
    }   
  }

  renderPagination() {
    let first_btn = (
      <li className={(this.state.page <= 1) ? 
                      'page-item disabled' : 'page-item'}>
        <a className="page-link" href="javascript:void(0);" tab-index="-1"
           onClick={(e) => this.switchPage(1, e)}>
          «
        </a>
      </li>
    );

    let previous_btn = (
      <li className={(this.state.page <= 1) ? 
                      'page-item disabled' : 'page-item'}>
        <a className="page-link" href="javascript:void(0);" tab-index="-1"
           onClick={(e) => this.switchPage((this.state.page - 1), e)}>
          ‹
        </a>
      </li>
    );

    let next_btn = (
      <li className={(this.state.page >= this.state.total_pages) ? 
                       'page-item disabled' : 'page-item'}>
        <a className="page-link" href="javascript:void(0);" tab-index="-1"
           onClick={(e) => this.switchPage((this.state.page + 1), e)}>
          ›
        </a>
      </li>
    );

    let last_btn = (
      <li className={(this.state.page >= this.state.total_pages) ? 
                      'page-item disabled' : 'page-item'}>
        <a className="page-link" href="javascript:void(0);" tab-index="-1"
           onClick={(e) => this.switchPage(this.state.total_pages, e)}>
          »
        </a>
      </li>
    );

    let start_pages_at = 1;
    let additional_pages = this.state.total_pages;

    if (additional_pages > 5) {
      additional_pages = 5;

      if (this.state.page > 4) {
        start_pages_at = (this.state.page - 2);
      }
    }

    let pages = Array.from({length: additional_pages}, (x,i) => i);


    let offset = 1;
    if (this.state.page > 1) {
      offset = (this.state.page * this.state.per_page);
    }

    let limit = this.state.per_page;
    if (this.state.page > 1) {
      limit = this.state.per_page + offset;
    }

    if (this.state.total_records < limit) {
      limit = this.state.total_records;
    }

    if (this.state.total_records < 1) {
      offset = 0;
    }

    if (this.state.total_pages <= 1) {
      return (
        <div className="row">
          <div className="col-md-6">
            Showing {offset} to {limit} of {this.state.total_records} entries.
          </div>
          <div className="col-md-6">
          </div>
        </div>
      );
    }


    return (
      <div className="row">
        <div className="col-md-6">
          Showing {offset} to {limit} of {this.state.total_records} entries.
        </div>
        <div className="col-md-6">
          <nav>
            <ul className="pagination justify-content-end">
              {first_btn}
              {previous_btn}
              {pages.map(function(page, pageIndex) {
                page = start_pages_at + page;
                if (page <= this.state.total_pages) {
                  return (
                    <li key={pageIndex} 
                        className={
                          (this.state.page == page) ? 
                          "page-item active" : "page-item"
                        }>
                      <a className="page-link" href="javascript:void(0);" 
                         onClick={(e) => this.switchPage(page, e)}>{(page)}</a>
                    </li>
                  )
                }
              }, this)}
              {next_btn}
              {last_btn}
            </ul>
          </nav>
        </div>
      </div>
    )
  }

  renderControls() {
    return (
      <div id="controls">
        <div className="row">
          <div className="col-md-8">
          </div>

          <div className="col-md-4">
            <input className="form-control" 
                   onChange={(e) => this.handleSearchChange(this, e)}
                   placeholder="Search" />
          </div>
        </div>
      </div>
    );
  }

  renderColValue(column, text, row) {
    if (typeof(column['render_callback']) === 'function') {
      return column['render_callback'](column, text, row);
    }

    if (typeof text === 'string' || text instanceof String) {
      if (text.match(
        /^[0-9]{4}\-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}\.[0-9]{3}Z$/
      )) {
        return (
          <Moment>{text}</Moment>
        );
      }
    }

    return text;
  }

  renderFilterDateTime(colIndex, column) {
    return (
     <OverlayTrigger
        trigger="click"
        placement="bottom"
        overlay={
          <Popover
            id={`popover-${colIndex}`}
            title="Filter by Date & Time">
            Hello
          </Popover>
        }>
        <i className="fa fa-filter"></i>
      </OverlayTrigger>
    )
  }

  renderFilter(colIndex, column) {
    if (typeof column['filter_type'] === 'string' && 
        column['filter_type'] != ""
    ) {
      return (
        <span className="filter-col" onClick={(e) => e.stopPropagation()}>
          {this.renderFilterDateTime()}
        </span>
      );
    }

    return;
  }

  renderColHead(colIndex, column) {
    let sorting_class = '';

    if (column['sorting']) {
      sorting_class = 'sorting';

      if (this.state.order_col == column['data']) {
        sorting_class = 'sorting_asc';

        if (this.state.order_direction == 'desc') {
          sorting_class = 'sorting_desc';
        }
      }
    }

    return (
      <th className={sorting_class} key={colIndex}
          onClick={(e) => this.handleColSort(this, e, column)}>
        {column['text']}
        {this.renderFilter(colIndex, column)}
      </th>
    );
  }

  renderSelectAll() {
    if (!this.props.row_select) {
      return;
    }

    return (
      <th className ='select-box switch-col'>
        <input type="checkbox" 
               onChange={(e) => this.handleSelectAllChange(this, e)}
               checked={this.state.all_selected} />
      </th>
    );
  }


  renderSelect(row) {
    if (!this.props.row_select) {
      return;
    }

    let checked = false;

    if (this.state.all_selected) {
      checked = true;
    }

    if (this.state.checked_ids.includes(row['id'])) {
      checked = true;
    }

    return (
      <td>
        <input type="checkbox" value={row['id']} 
               onChange={(e) => this.handleSelectChange(this, e, row['id'])} 
               checked={checked} />
      </td>
    );
  }

  renderMoveCol() {
    if (this.state.search.length > 0) {
      return;
    }

    if (typeof this.props.enableRowDragDrop !== 'undefined' &&
        this.props.enableRowDragDrop &&
        typeof this.props.rowDragDropIdCol !== 'undefined' && 
        this.props.rowDragDropIdCol.length > 0
    ) {
      return (
        <th className="switch-col"></th>
      )
    }

    return;
  }

  renderMoveRowToolTip(props) {
    return(
      <Tooltip {...props}>
        Move
      </Tooltip>
    )
  }

  renderMoveRow(row, provided) {
    if (!provided) {
      return;
    }


    if (typeof this.props.enableRowDragDrop !== 'undefined' &&
        this.props.enableRowDragDrop &&
        typeof this.props.rowDragDropIdCol !== 'undefined' && 
        this.props.rowDragDropIdCol.length > 0
    ) {
      return (
        <td>
          <OverlayTrigger
            placement="top"
            overlay={this.renderMoveRowToolTip}
          >        
            <i className="fa fa-arrows-alt"
              {...provided.dragHandleProps}>
            </i>
          </OverlayTrigger>
        </td>
      )
    }

    return;    
  }

  onEnableDisableToggle(event, row, rowIndex) {
    event.preventDefault();

    this.onEnableDisableCallback(row, rowIndex);
  }

  async onEnableDisableCallback(row, rowIndex) {
    let rows = this.state.rows;
    let row_enabled = rows[rowIndex]['enabled'];

    if (row_enabled) {
      rows[rowIndex]['enabled'] = false;
    } else {
      rows[rowIndex]['enabled'] = true;
    }

    this.setState({
      rows: rows
    });

    if (typeof this.props.onEnableDisableCallback !== 'undefined') {
      if (await this.props.onEnableDisableCallback(row, row_enabled)) {
        this.updateData();
      }
    }
  }


  renderEnableCol() {
    if (typeof this.props.enableRowEnableDisable !== 'undefined' &&
        this.props.enableRowEnableDisable
    ) {
      return (
        <th className="switch-col"></th>
      )
    }

    return;    
  }


  renderEnableRowToolTip(props) {
    return(
      <Tooltip {...props}>
        Enable / Disable
      </Tooltip>
    )
  }

  renderEnableRow(row, rowIndex) {
    if (typeof this.props.enableRowEnableDisable !== 'undefined' &&
        this.props.enableRowEnableDisable
    ) {
      return (
        <td>
          <OverlayTrigger
            placement="top"
            overlay={this.renderEnableRowToolTip}
          >        
            <i className="fa fa-check" onClick={
              (e) => this.onEnableDisableToggle(e, row, rowIndex)
            } />
          </OverlayTrigger>
        </td>
      )
    }

    return;    
  }  

  renderRow(row, rowIndex, provided=null) {
    return (
      <React.Fragment>
        {this.renderSelect(row)}
        {this.props.columns.map(function(column, colIndex) {
          return (
            <td key={colIndex}>
              {this.renderColValue(column, row[column['data']], row)}
            </td>
          );
        }, this)}
        {this.renderEnableRow(row, rowIndex)}        
        {this.renderMoveRow(row, provided)}
      </React.Fragment>
    )
  }


  renderTbody() {
    if (typeof this.props.enableRowDragDrop !== 'undefined' &&
        this.props.enableRowDragDrop &&
        typeof this.props.rowDragDropIdCol !== 'undefined' && 
        this.props.rowDragDropIdCol.length > 0 &&
        this.state.search.length < 1
    ) {

      let droppableRandId = Math.random().toString(36).substring(7);

      return (
        <Droppable droppableId={droppableRandId}>
          {(provided) => (
            <tbody ref={provided.innerRef}
                   {...provided.droppableProps}>
              {this.state.rows.map(function(row, rowIndex) {
                return (
                  <Draggable key={row[this.props.rowDragDropIdCol]}
                             draggableId={row[this.props.rowDragDropIdCol]}
                             index={rowIndex}>
                    {(
                      provided,
                      snapshot
                      ) => (
                      <DataTableRow  {...provided.draggableProps}
                          ref={provided.innerRef}
                          snapshot={snapshot}
                          className={
                            ((typeof row['enabled'] !== 'undefined') ? 
                              ((row['enabled']) ? '' : 'disabled-row') : ''
                            )
                          }>
                          {this.renderRow(row, rowIndex, provided)}
                      </DataTableRow>
                    )}
                  </Draggable>
                )
              }, this)}
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      )
    }

    return (
      <tbody>
        {this.state.rows.map(function(row, rowIndex) {
        return (
          <tr key={rowIndex}>
            {this.renderRow(row, rowIndex)}
          </tr>
          );
        }, this)}
      </tbody>    
    );
  }

  loadingCallback() {
    this.setState({
      loading: true
    });
    if (typeof this.props.loadingCallback !== 'undefined') {
      this.props.loadingCallback();
    }
  }

  loadedCallback() {
    this.setState({
      loading: false
    });
    if (typeof this.props.loadedCallback !== 'undefined') {
      this.props.loadedCallback();
    }
  }

  selectedRowCallback(rows) {
    if (typeof this.props.selectedRowCallback !== 'undefined') {
      this.props.selectedRowCallback(rows);
    }
  }

  async updateData() {
    this.loadingCallback();
    try {
      let result = fetch(this.props.api +
        "?per_page="+ this.state.per_page +
        "&page="+this.state.page +
        "&search=" + this.state.search +
        "&order_col=" + this.state.order_col +
        "&order_direction=" + this.state.order_direction,
        {credentials: 'same-origin'}
      );
      
      result = await fetchErrorHandler(result);
      const data = await result.json();
      if (data.status == "ok") {
        this.setState({
          rows: data.data.data,
          total_pages: data.data.total_pages,
          total_records: data.data.total_records,

          // Reset selected checkboxes
          row_ids: data.data.data.map(function(row, rowIndex) {
            return row['id']
          }),
          checked_ids: [],
          all_selected: false
        });

        this.loadedCallback();
      } else {
        throw Error([500, data.message]);
      }
    } catch(error) {
      this.setState({caughtError: error});
    }
  }

  componentDidMount() {
    this.updateData();
  }

  render() {
    if (this.state.caughtError) {
      throw this.state.caughtError;
    }

    let col_count = this.props.columns.length;
    let row_count = this.state.rows.length;
    if (this.props.row_select) {
      col_count += 1;
    }

    let tbody = this.renderTbody();

    if (row_count < 1) {
      tbody = (
        <tbody>
          <tr>
            <td colSpan={col_count}>
              No results found.
            </td>
          </tr>
        </tbody>
      )
    }

    if (this.state.loading) {
      tbody = (
        <tbody>
          <tr>
            <td className="react-loader-table" 
                colSpan={col_count}>
              <Loading loading={this.state.loading} />
            </td>
          </tr>
        </tbody>
      )
    }


    
    return (
      <div className='datatable'>
        {this.renderControls()}
        <DragDropContext onDragEnd={this.onDragEnd}>
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                {this.renderSelectAll()}
                {this.props.columns.map(function(column, colIndex) {
                  return this.renderColHead(colIndex, column);
                }, this)}
                {this.renderEnableCol()}                
                {this.renderMoveCol()}
              </tr>
            </thead>

            {tbody}    
          </table>
        </DragDropContext>

        {this.renderPagination()}
      </div>
    );
  }
};


DataTable.propTypes = {
  api: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  default_order_col: PropTypes.string,
  default_order_dir: PropTypes.string,
  row_select: PropTypes.bool
};

export default DataTable;
