import React from "react";
import IdmForm from './Form';
import fetchErrorHandler from '../errors/fetchErrorHandler.js';
import fetchJsonResultHandler from '../misc/fetchJsonResultHandler.js';
import readCookie from '../misc/readCookie.js';

class FormCommon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type == 'checkbox' ? target.checked : target.value;
    const name = target.id 

    this.props.setUpperState({
      [name] : value
    });
  }  

  handleChangeCustomAttribute(attribute_name, value) {
    let custom_attribute_values = this.props.formState.custom_attribute_values;

    custom_attribute_values[attribute_name] = value;

    this.props.setUpperState({
      custom_attribute_values: custom_attribute_values
    });
  }

  async componentDidMount() {
    try {
      let result = await fetch(
        '/api/internal/idm/custom_attributes',
        {credentials: 'same-origin'}
      );

      result = await fetchErrorHandler(result);
      let data = await fetchJsonResultHandler(result);

      if (data.status == "ok") {
        this.setState({
          loading: false,
        });

        this.props.setUpperState({
          custom_attributes: data.data.data
        });
      }
    } catch (error) {
      this.props.setUpperState({caughtError: error});
    }
  }

  render() {
    return (
      <IdmForm 
        formState={this.props.formState}
        additionalState={this.state}
        handleChange={this.handleChange.bind(this)}
        handleChangeCustomAttribute={
          this.handleChangeCustomAttribute.bind(this)
        }
        onSubmit={this.props.onSubmit.bind(this)}
        password_input_ref={this.props.password_input_ref}
        confirm_password_input_ref={this.props.confirm_password_input_ref}
        setUpperState={this.props.setUpperState}
        sendPwReset={this.props.sendPwReset}
        />
    )
  }   
}

export default FormCommon
