export default function fetchErrorHandler(response) {
  if (typeof response.ok === 'undefined') {
    return response;
  }  
  if (!response.ok) {
    throw new Error([response.status, response.statusText]);
  }

  return response;
}
