import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";

import GranularAccessRuleIndex from './ReadonlyIndex';
import GranularAccessEditRule from './Edit';

import Error404 from '../../errors/Error404';
import Error500 from '../../errors/Error500';


class GranularAccessReadonly extends React.Component {
  render() {
    return(
      <HashRouter>
        <Error500>
          <Switch>
            <Route exact path="/" component={GranularAccessRuleIndex} />
            <Route exact path="/view/:rule_id" 
                         component={GranularAccessEditRule} />
            <Route component={Error404} />
          </Switch>
        </Error500>
      </HashRouter>
    )
  }
}

export default GranularAccessReadonly
