import React from "react";
import { Link } from "react-router-dom";
import fetchErrorHandler from '../errors/fetchErrorHandler.js';
import fetchJsonResultHandler from '../misc/fetchJsonResultHandler.js';
import readCookie from '../misc/readCookie.js';
import DataTable from '../misc/DataTable';
import Modal from 'react-bootstrap/Modal';
import Loading from '../misc/Loading.jsx';
import RenderError from '../misc/RenderError';
import styled from 'styled-components';



class IdmIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      caughtError: '',
      loading: true,
      selectedRows: [],
      showDeleteModal: false,
      error_message: '',
      csvImportEnabled: false
    }

    this.loadedCallback = this.loadedCallback.bind(this);
    this.selectedRowCallback = this.selectedRowCallback.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteModalOpen = this.handleDeleteModalOpen.bind(this);
    this.handleDeleteModalClose = this.handleDeleteModalClose.bind(this);
    this.handleDeleteSelected = this.handleDeleteSelected.bind(this);   
  }

  selectedRowCallback(rows) {
    this.setState({
      selectedRows: rows
    });
  }

  handleDeleteModalOpen() {
    this.setState({
      showDeleteModal: true,
    });
  }

  handleDeleteModalClose() {
    this.setState({
      showDeleteModal: false,
    });
  }

  handleConfigModalOpen() {
    this.setState({
      showConfigModal: true,
      defaultActionTmp: this.state.defaultAction
    });
  }

  handleConfigModalClose() {
    this.setState({
      showConfigModal: false,
      defaultActionTmp: this.state.defaultAction
    });
  }

  loadedCallback() {
    this.setState({
      loading: false
    });
  }

  async handleDelete(event) {
    event.preventDefault();
  
    this.setState({
      loading: true,
      showDeleteModal: false
    });
    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"));

    var errored = false;


    try {
      for (var key in this.state.selectedRows) {
        let user_id = this.state.selectedRows[key];
        let formData = new FormData();  
        formData.append('authenticity_token', csrf_token)

        let result = await fetch(
          '/api/internal/idm/user/' + user_id,{
            method: 'DELETE',
            body: formData,
            credentials: 'same-origin'
          }
        );

        result = await fetchErrorHandler(result);
        const data = await fetchJsonResultHandler(result);
        if (data.status != "ok") {
          errored = true;
          this.setState({
            loading: false,
            error_message: data.message,
          });
          break;
        }
      }
    } catch (error) {
      errored = true;
      this.setState({caughtError: error});
    }

    if (!errored) {
      window.location.reload();
    }
  }

  handleDeleteSelected(event) {
    event.preventDefault();

    this.setState({
      showDeleteModal: true
    });
  }

  async componentDidMount() {
    try {
      let result = await fetch(
        '/api/internal/idm/settings',
        {credentials: 'same-origin'}
      );

      result = await fetchErrorHandler(result);
      let data = await fetchJsonResultHandler(result);

      if (data.status == "ok") {
        this.setState({
          loading: false,
          csvImportEnabled: data.data.csv_import_enabled
        });
      }
    } catch (error) {
      this.setState({caughtError: error});
    }    
  }

  renderUsername(column, text, row) {
    return(
      <Link to={"/edit/" + row['id']}>
        {text}
      </Link>
    )
  }

  
  render() {
    if (this.state.caughtError) {
      throw this.state.caughtError;
    }

    if (this.state.loading) {
      return (
        <div className="col-sm-12 react-loader">
          <Loading loading={this.state.loading} />
        </div>
      );
    }

    var csv_import_link = "";

    if (this.state.csvImportEnabled) {
      csv_import_link = (
        <Link to='/import' className="btn btn-secondary">CSV import</Link>
      )
    }

    return (
      <div className="bgc-white bd bdrs-3 p-20 mB-20">
        <h5 className="c-grey-900 mB-20">Identity manager</h5>
        <RenderError error_message={this.state.error_message} />      

        <div className="row">
          <div className="col-sm-6">
            <button className="btn btn-danger"
                    onClick={this.handleDeleteSelected}>
              Delete selected
            </button>&nbsp;
            <Link to='/new' className="btn btn-primary">Add new</Link>
            &nbsp;{csv_import_link}

          </div>

          <div className="col-sm-6">
          </div>
        </div>        
        <br />
        <div className="row">
          <div className="col-sm-12">
            <DataTable
              api="/api/internal/idm/users.json"
              columns={[
                {
                  data: 'username', text: "Username", sorting: true,
                  render_callback: this.renderUsername
                },
                {
                  data: 'email', text: "E-mail", sorting: true
                },
                {
                  data: 'firstName', text: "First name", sorting: true
                },
                {
                  data: 'lastName', text: "Last name", sorting: true
                }               
              ]}

              loadedCallback={this.loadedCallback}
              selectedRowCallback={this.selectedRowCallback}
              default_order_col="username"
              default_order_dir="asc"
              row_select={true}
              per_page={10}
               />
     

            <Modal show={this.state.showDeleteModal} 
                   onHide={this.handleDeleteModalClose}>
              <Modal.Header closeButton>
                <Modal.Title>Delete user</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                Are you sure you want to delete the selected users?
              </Modal.Body>

              <Modal.Footer>
                <button className="btn btn-primary"
                        onClick={this.handleDeleteModalClose}>Close</button>
                <button className="btn btn-danger"
                        onClick={this.handleDelete}>Delete</button>
              </Modal.Footer>
            </Modal>       

          </div>
        </div>
      </div>
    )
  }
}

export default IdmIndex
