import * as $ from 'jquery';
import CodeMirror from 'codemirror';
import 'codemirror/mode/xml/xml';

class Codeeditor {
  constructor(element, readOnly) {
    this.state = {
      element: element
    }

    this.init(this, readOnly);

  }

  betterTab(cm) {
    if (cm.somethingSelected()) {
      cm.indentSelection("add");
    } else {
      cm.replaceSelection(cm.getOption("indentWithTabs")? "\t":
      Array(cm.getOption("indentUnit") + 1).join(" "), "end", "+input");
    }
  }

  init(self, readOnly) {
    var myCodeMirror = CodeMirror.fromTextArea(document.getElementById(self.state.element),
    {
      lineNumbers: true,
      mode: "xml",
      indentUnit: 4,
      tabSize: 4,
      indentWithTabs: false,
      extraKeys: { Tab: self.betterTab },
      readOnly: readOnly,
      viewportMargin: 100000000
    });

  }


}

export default Codeeditor
