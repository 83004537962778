import Statistics from './statistics';

class StatisticsSummary extends Statistics {
  constructor() {
    super();
    
    this.init(gon.date_format, null, null, null, null, 'chart');
    this.chartInit(gon.authentications, "AreaChart")
    
    if(jQuery(gon.failed_authentications).length > 0) {
      this.chartInit(gon.failed_authentications, "AreaChart", 'chart_failed')
    }
    this.chartInit(gon.resources, "BarChart", 'chart_resources')
  }
}

export default StatisticsSummary;
