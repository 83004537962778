import Loadbalancesync from './components/loadbalancesync/index';
import Codeeditor from './components/codeeditor/index';

import DiagnosticsLogViewer from './components/logviewers/diagnostics';
import DashboardLogs from './components/logviewers/dashboard';
import SavedReports from './components/statistics/saved_reports';
import DeviceAgents from './components/logviewers/agents';

import Statistics from './components/statistics/statistics';
import StatisticsSummary from './components/statistics/summary';
import AuthenticationsStatistics from './components/statistics/authentications';
import ResourcesStatistics from './components/statistics/resources';
import HeatMapStatistics from './components/statistics/heatmap';

import './raw_data_extract';

export const App = ($) => {
  jQuery.ajaxSetup({
    headers: {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    }
  });

  $(".collapse-btn i").click(function() {
    $("div.layout").toggleClass("layout-collapsed");
  });
  // Load load balance sync class
  new Loadbalancesync("#loadbalancesynclink", "#loadbalancesyncmodal", "#loadbalancesynctail");



  // Load elements with gon dependency
  if (typeof gon !== 'undefined') {
    // Load code mirror code editor
    if (gon.codemirrorHelper && gon.codemirrorElement) {
      let readOnly = false;

      if (typeof gon.codemirrorReadOnly !== 'undefined') {
        readOnly = gon.codemirrorReadOnly;
      }
      new Codeeditor(gon.codemirrorElement, readOnly);
    }

    if (gon.datatablesHelper) {
      if (gon.datatablesInput == "#diag_logs_table") {
        new DiagnosticsLogViewer();
      }

      if (gon.datatablesInput == '#logs_table') {
        new DashboardLogs();
      }

      if (gon.datatablesInput == '#saved_reports_table') {
        new SavedReports();
      }

      if (gon.datatablesInput == "#device_agent" || gon.datatablesInput == "#device_agent_unapproved") {
        new DeviceAgents();
      }
    }

    if (gon.statisticsHelper) {
      var ranges = {}

      if (typeof gon.translations != 'undefined') {
        ranges[gon.translations.today] = [
          moment().startOf('day'),
          moment().endOf('day')
        ];
        ranges[gon.translations.yesterday] = [
          moment().subtract(1, 'days').startOf('day'),
          moment().subtract(1, 'days').endOf('day')
        ];
        ranges[gon.translations.last_24_hrs] = [
          moment().subtract(24, 'hours'),
          moment()
        ];
        ranges[gon.translations.last_7_days] = [
          moment().subtract(6, 'days').startOf('day'),
          moment()
        ];
        ranges[gon.translations.last_30_days] = [
          moment().subtract(29, 'days').startOf('day'),
          moment()
        ];
        ranges[gon.translations.this_month] = [
          moment().startOf('month'),
          moment().endOf('month')
        ];
        ranges[gon.translations.last_month] = [
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month')
        ];
        ranges[gon.translations.this_year] = [
          moment().startOf('year'),
          moment()
        ];
        ranges[gon.translations.last_year] = [
          moment().subtract(1, 'year').startOf('year'),
          moment().subtract(1, 'year').endOf('year')
        ];
      }

      if (gon.statisticsHelperPage == 'summary') {
        new StatisticsSummary();
      }

      if (gon.statisticsHelperPage == 'authentications') {
        new AuthenticationsStatistics(ranges);
      }

      if (gon.statisticsHelperPage == 'resources') {
        new ResourcesStatistics(ranges);
      }

      if (gon.statisticsHelperPage == 'user_location') {
        new HeatMapStatistics(ranges);
      }

      if (gon.statisticsHelperPage == 'resolvertest') {
        let statisticsHelper = new Statistics;
        statisticsHelper.multiSelectizeInit($("#service-provider-select"), null);
      }
    }

    if (gon.rawConfigFileBrowser) {
      jQuery('#rawConfigFileBrowser').dataTable({
        "pageLength": 15,
        "lengthMenu": [10, 15, 20, 25, 30, 50, 100]
      });
    }

  }
};
