import React from "react";
import fetchErrorHandler from '../errors/fetchErrorHandler.js';
import fetchJsonResultHandler from '../misc/fetchJsonResultHandler.js';
import Loading from '../misc/Loading.jsx';
import Modal from 'react-bootstrap/Modal';

class Shibv4Check extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      caughtError: '',
      loading: true,
      showConfigModal: false,
      showWarnModal: false,
      data: {
        java_check: null,
        jetty_check: null,
        warnlog_check: null,
        config_check: null
      }
    }
  }

  handleConfigModalOpen() {
    this.setState({
      showConfigModal: true,
    });
  }

  handleConfigModalClose() {
    this.setState({
      showConfigModal: false,
    });
  }

  handleWarnModalOpen() {
    this.setState({
      showWarnModal: true,
    });
  }

  handleWarnModalClose() {
    this.setState({
      showWarnModal: false,
    });
  }


  async componentDidMount() {
    try {
      let result = await fetch(
        '/api/internal/configuration/shibv4check/perform',
        {credentials: 'same-origin'}
      );

      result = await fetchErrorHandler(result);
      let data = await fetchJsonResultHandler(result);

      if (data) {
        this.setState({
          loading: false,
          data
        });
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }

  render() {
    if (this.state.caughtError) {
      throw this.state.caughtError;
    }

    if (this.state.loading) {
      return (
        <div className="col-sm-12 react-loader">
          <Loading loading={this.state.loading} />
        </div>
      );
    }

    const self=this;
    return (
      <React.Fragment>
        <div className="bgc-white bd bdrs-3 p-20 mB-20">
          <h5 className="c-grey-900 mB-20">Shibboleth v4 readiness check</h5>

          <table className="table table-striped">
            <thead>
              <tr>
                <th>Check</th>
                <th>Passed</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Java version</td>
                <td>
                  {(this.state.data.java_check) ? 
                    (this.state.data.java_check.passed) ? 
                    <span className="badge badge-success">Passed</span> : 
                    <span className="badge badge-danger">Failed</span> : ''}
                </td>
                <td>
                  {(this.state.data.java_check) ? 
                    `${this.state.data.java_check.version} (expected v11 or newer)` : ''}
                </td>
              </tr>

              <tr>
                <td>Jetty/Tomcat version</td>
                <td>
                  {(this.state.data.jetty_check) ? 
                    (this.state.data.jetty_check.passed) ? 
                    <span className="badge badge-success">Passed</span> :
                    <span className="badge badge-danger">Failed</span> : ''}
                </td>
                <td>
                  {(this.state.data.jetty_check) ? 
                    `${this.state.data.jetty_check.version} (expected jetty 9.4+ or tomcat 8+)` : ''}
                </td>
              </tr>

              <tr>
                <td>Deprecation notices in idp-warn logs</td>
                <td>
                  {(this.state.data.warnlog_check) ? 
                    (this.state.data.warnlog_check.passed) ? 
                    <span className="badge badge-success">Passed</span> :
                    <span className="badge badge-danger">Failed</span> : ''}
                </td>
                <td>
                {(this.state.data.warnlog_check) ? 
                    (!this.state.data.warnlog_check.passed) ? 
                      (<a href="#" onClick={(e) => self.handleWarnModalOpen()}>View errors</a>) : '' : '' }
                </td>
              </tr>

              <tr>
                <td>Config file validation</td>
                <td>
                  {(this.state.data.config_check) ? 
                    (this.state.data.config_check.passed) ? 
                    <span className="badge badge-success">Passed</span> :
                    <span className="badge badge-danger">Failed</span> : ''}
                </td>
                <td>
                  {(this.state.data.config_check) ? 
                    (!this.state.data.config_check.passed) ? 
                      (<a href="#" onClick={(e) => self.handleConfigModalOpen()}>View errors</a>) : '' : '' }
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <Modal show={this.state.showConfigModal} 
          dialogClassName="modal-dialog-huge"
                   onHide={this.handleConfigModalClose.bind(self)}>
              <Modal.Header closeButton>
                <Modal.Title>Config validation check</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                {
                  this.state.data.config_check.results.map((result,index) => {
                    if (result.passed) {
                      return <React.Fragment/>;
                    }

                    return(
                      <div key={index}>
                        <h4>{result.file}</h4>
                        <table className='table table-bordered table-striped'>
                          <tbody>
                            {result.errors.map((error, errIndex) => {
                              return(
                                <tr key={errIndex}>
                                  <td>{error}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    )
                  })
                }
              </Modal.Body>

              <Modal.Footer>
                <button className="btn btn-primary"
                        onClick={this.handleConfigModalClose.bind(self)}>Close</button>
              </Modal.Footer>
            </Modal>

            <Modal show={this.state.showWarnModal} 
          dialogClassName="modal-dialog-huge"
                   onHide={this.handleWarnModalClose.bind(self)}>
              <Modal.Header closeButton>
                <Modal.Title>Warn log depreciation check</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <table className='table table-bordered table-striped'>
                  <tbody>
                    {this.state.data.warnlog_check.items.map((error, errIndex) => {
                      return(
                        <tr key={errIndex}>
                          <td>{error}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </Modal.Body>

              <Modal.Footer>
                <button className="btn btn-primary"
                        onClick={this.handleWarnModalClose.bind(self)}>Close</button>
              </Modal.Footer>
            </Modal>
      </React.Fragment>
    )
  }
}

export default Shibv4Check