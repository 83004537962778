import React from "react";
import fetchErrorHandler from '../errors/fetchErrorHandler.js';
import fetchJsonResultHandler from '../misc/fetchJsonResultHandler.js';
import readCookie from '../misc/readCookie.js';
import Loading from '../misc/Loading.jsx';
import RenderError from '../misc/RenderError';
import RenderSuccess from '../misc/RenderSuccess';
import { Link } from "react-router-dom";


class IdmImport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      caughtError: '',
      loading: true,
      importedData: [],
      error_message: '',
      file: null
    }           
  }

  async onSubmit(event) {
    event.preventDefault();
    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"))

    this.setState({
      loading: true
    });

    let formData = new FormData();
    formData.append('authenticity_token', csrf_token);
    formData.append('file', this.state.file);

    try {
      let result = await fetch(
        '/api/internal/idm/users/csv.json',{
          method: 'POST',
          body: formData,
          credentials: 'same-origin'
        }
      );

      result = await fetchErrorHandler(result);
      const data = await fetchJsonResultHandler(result);
      if (data.status == "ok") {
        this.setState({
          loading: false,
          error_message: '',
          importedData: data.data
        });
      } else {
        this.setState({
          loading: false,
          error_message: data.message,
          importedData: []
        });
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }

  async componentDidMount() {
    try {
      let result = await fetch(
        '/api/internal/idm/settings',
        {credentials: 'same-origin'}
      );

      result = await fetchErrorHandler(result);
      let data = await fetchJsonResultHandler(result);

      if (data.status == "ok") {
        if (!data.data.csv_import_enabled) {
          window.location.href = "/idm";
        } else {
          this.setState({
            loading: false,
          });
        }
      }
    } catch (error) {
      this.setState({caughtError: error});
    }    
  }  

  selectFileHandler(event) {
    this.setState({
      file: event.target.files[0]
    });
  }

  goBack(event) {
    event.preventDefault();
    this.setState({
      error_message: '',
      importedData: []
    });
  }

  renderImportedData(row, index) {
    var badgeClass = "badge badge-success";
    if (row.status == "failed") {
      badgeClass = "badge badge-danger";
    }

    var errorMessage = "";

    if (row.message) {
      errorMessage = (
        <span className="error">{row.username}: {row.message}
        </span>
      );
    }

    var message = row.username;

    if (row.message) {
      message = errorMessage;
    }

    return (
      <li key={index}>
        <span className={badgeClass}>
          {row.status.toUpperCase()}
        </span>

        {message}
      </li>
    )
  }

  render() {
    if (this.state.caughtError) {
      throw this.state.caughtError;
    }

    if (this.state.loading) {
      return (
        <div className="col-sm-12 react-loader">
          <Loading loading={this.state.loading} />
        </div>
      );
    }


    if (this.state.importedData.length > 0) {
      return (
        <div className="bgc-white bd bdrs-3 p-20 mB-20">
         <h4 className="c-grey-900 mB-20">Import CSV</h4>

        <div className="row">
          <div className="col-sm-12">
            <h6>Output</h6>
            <ul id="idm_csv_import">
              {this.state.importedData.map(
                this.renderImportedData.bind(this)
              )}
            </ul>

             <p>
              <button className="btn btn-primary btn-sm"
                      onClick={this.goBack.bind(this)}>
                Go back
              </button>     
             </p>  
          </div>
        </div>
       </div>
      )
    }

    return (
      <div className="bgc-white bd bdrs-3 p-20 mB-20">
        <h4 className="c-grey-900 mB-20">Import CSV</h4>

        <div className="row">
          <div className="col-sm-12">

            <RenderError error_message={this.state.error_message} />

            <form onSubmit={this.onSubmit.bind(this)}>
              <div className="form-group">
                <label>
                  <h6>Select a CSV file to import</h6> 
                  <em>
                    <a href='/api/internal/idm/users/csv.csv'>
                      (Download template)
                    </a>
                  </em>
                </label>
                <input type="file" id="file"
                       className="form-control" required={true}
                       onChange={this.selectFileHandler.bind(this)} />
              </div>

              <p>
               <input type="submit" className="btn btn-primary" value="Import"/>
               &nbsp;
              <Link to="/" className="btn btn-default">
                Go back
              </Link>     
             </p>                                    
            </form>
          </div>
        </div>      
      </div>
    )
  }
}

export default IdmImport
