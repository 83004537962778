import React from "react";
import PredefinedForm from './Form';
import readCookie from '../../misc/readCookie.js';
import fetchErrorHandler from '../../errors/fetchErrorHandler.js';
import fetchJsonResultHandler from '../../misc/fetchJsonResultHandler.js';

class PredefinedFormCommon extends React.Component {
  constructor(props) {
    super(props);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type == 'checkbox' ? target.checked : target.value;
    const name = target.id 

    this.props.setUpperState({
      [name] : value
    });
  }  


  onAddAllowCondition(event) {
    event.preventDefault();

    let attributeSelectValues = this.props.formState.attributeSelectValues;
    let attributeInputValues = this.props.formState.attributeInputValues;
    let attributeRegexInputValues = this.props.formState.attributeRegexInputValues;
    let attributeMultiValueInputValues = this.props.formState.attributeMultiValueInputValues;

    let id = this.props.formState.allow_attribute_boxes;
    attributeSelectValues.allow[id] = null;
    attributeInputValues.allow[id] = "";
    attributeRegexInputValues.allow[id] = false;
    attributeMultiValueInputValues.allow[id] = false;

    this.props.setUpperState({
      attributeSelectValues: attributeSelectValues,
      attributeInputValues: attributeInputValues,
      attributeRegexInputValues: attributeRegexInputValues,
      attributeMultiValueInputValues: attributeMultiValueInputValues,
      allow_attribute_boxes: (this.props.formState.allow_attribute_boxes + 1)
    });
  }

  onAddDenyCondition(event) {
    event.preventDefault();

    let attributeSelectValues = this.props.formState.attributeSelectValues;
    let attributeInputValues = this.props.formState.attributeInputValues;
    let attributeRegexInputValues = this.props.formState.attributeRegexInputValues;
    let attributeMultiValueInputValues = this.props.formState.attributeMultiValueInputValues;

    let id = this.props.formState.deny_attribute_boxes;
    attributeSelectValues.deny[id] = null;
    attributeInputValues.deny[id] = "";
    attributeRegexInputValues.deny[id] = false;
    attributeMultiValueInputValues.deny[id] = false;

    this.props.setUpperState({
      attributeSelectValues: attributeSelectValues,
      attributeInputValues: attributeInputValues,
      attributeRegexInputValues: attributeRegexInputValues,
      attributeMultiValueInputValues: attributeMultiValueInputValues,      
      deny_attribute_boxes: (this.props.formState.deny_attribute_boxes + 1)
    });
  }

  onAllowDenySelectChange(val, id, type='allow') {
    let attributeSelectValues = this.props.formState.attributeSelectValues;
    if (val && typeof val.value !== 'undefined') {
      attributeSelectValues[type][id] = {value: val.value, label: val.label};
    } else{
      attributeSelectValues[type][id] = null;
    }
    this.props.setUpperState({
      attributeSelectValues: attributeSelectValues
    });
  }

  onAllowDenyInputChange(val, id, type='allow') {
    let attributeInputValues = this.props.formState.attributeInputValues;

    if (val) {
      attributeInputValues[type][id] = val;
    } else{
      attributeInputValues[type][id] = "";
    }

    this.props.setUpperState({
      attributeInputValues: attributeInputValues
    });
   
  }

  onAllowDenyValueTypeSelectChange(val, id, type='allow') {
    let attributeRegexInputValues = this.props.formState.attributeRegexInputValues;    
    let attributeMultiValueInputValues = this.props.formState.attributeMultiValueInputValues;

    if (val.value == "regex") {
      attributeRegexInputValues[type][id] = true;
      attributeMultiValueInputValues[type][id] = false; 
    } else if (val.value == "multi_value") {
      attributeRegexInputValues[type][id] = false;
      attributeMultiValueInputValues[type][id] = true;       
    } else {
      attributeRegexInputValues[type][id] = false;
      attributeMultiValueInputValues[type][id] = false;      
    }

    this.props.setUpperState({
      attributeRegexInputValues: attributeRegexInputValues,
      attributeMultiValueInputValues: attributeMultiValueInputValues
    });

  }

  removeBox(e, id, type='allow') {
    e.preventDefault();

    let allow_attribute_boxes = this.props.formState.allow_attribute_boxes;
    let deny_attribute_boxes = this.props.formState.deny_attribute_boxes;

    let attributeSelectValues = this.props.formState.attributeSelectValues;
    let attributeInputValues = this.props.formState.attributeInputValues;
    let attributeRegexInputValues = this.props.formState.attributeRegexInputValues;
    let attributeMultiValueInputValues = this.props.formState.attributeMultiValueInputValues;

    attributeSelectValues[type].splice(id, 1);
    attributeInputValues[type].splice(id, 1);
    attributeRegexInputValues[type].splice(id, 1);
    attributeMultiValueInputValues[type].splice(id, 1);

    if (type == 'allow') {
      allow_attribute_boxes -= 1;
    } else {
      deny_attribute_boxes -= 1;
    }

    this.props.setUpperState({
      attributeSelectValues: attributeSelectValues,
      attributeInputValues: attributeInputValues,
      attributeRegexInputValues: attributeRegexInputValues,
      attributeMultiValueInputValues: attributeMultiValueInputValues,
      allow_attribute_boxes: allow_attribute_boxes,
      deny_attribute_boxes: deny_attribute_boxes
    });
  }  

  handleUsernameSelectChange(id, type, val, multiValue=false) {
    let raw_value = null;


    if (val != null && multiValue) {
      raw_value = val.map(function(value, index) {
        return value.value;
      }).join(",");
    } else if (val != null) {
      raw_value = val.value;
    }

    let attributeInputValues = this.props.formState.attributeInputValues;
    attributeInputValues[type][id] = raw_value;

    this.props.setUpperState({
      attributeInputValues: attributeInputValues
    });
  }  

  handleGroupSelectChange(id, type, val, multiValue=false) {
    let raw_value = null;

    if (val != null && multiValue) {
      raw_value = val.map(function(value, index) {
        return value.value;
      }).join(",");
    } else if (val != null) {
      raw_value = val.value;
    }

    let attributeInputValues = this.props.formState.attributeInputValues;
    attributeInputValues[type][id] = raw_value;

    this.props.setUpperState({
      attributeInputValues: attributeInputValues
    });
  }


  async searchUsername(input) {
    try {
      let result = await fetch(
        '/api/internal/statistics/search/auditlog_users?term='+input, 
        {credentials: 'same-origin'}
      );

      result = await fetchErrorHandler(result);
      let data = await result.json();

      if (data.status == "ok") {
        return data.data.users.map(function(user, index) {
          return { value: user, label: user }
        });
      }
      
    } catch (error) {
      this.props.setUpperState({caughtError: error});
    }

    return null;
  }  

  async searchGroup(input) {
    try {
      let result = await fetch(
        '/api/internal/statistics/search/ldap_groups?term='+input, 
        {credentials: 'same-origin'}
      );

      result = await fetchErrorHandler(result);
      let data = await result.json();

      if (data.status == "ok") {
        return data.data.groups.map(function(user, index) {
          return { value: user, label: user }
        });
      }
      
    } catch (error) {
      this.props.setUpperState({caughtError: error});
    }

    return null;
  }    


  render() {
    return (
      <PredefinedForm 
        formState={this.props.formState}
        
        searchUsername={this.searchUsername.bind(this)}
        searchGroup={this.searchGroup.bind(this)}
        onSubmit={this.props.onSubmit.bind(this)}
        onAddAllowCondition={this.onAddAllowCondition.bind(this)}
        onAddDenyCondition={this.onAddDenyCondition.bind(this)}
        onAllowDenySelectChange={this.onAllowDenySelectChange.bind(this)}
        onAllowDenyInputChange={this.onAllowDenyInputChange.bind(this)}
        onAllowDenyValueTypeSelectChange={this.onAllowDenyValueTypeSelectChange.bind(this)}

        handleChange={this.handleChange.bind(this)}
        removeBox={this.removeBox.bind(this)}
        handleUsernameSelectChange={this.handleUsernameSelectChange.bind(this)}
        handleGroupSelectChange={this.handleGroupSelectChange.bind(this)}
      />
    )
  }
}

export default PredefinedFormCommon
