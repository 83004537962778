import * as $ from 'jquery';
import moment from 'moment';
import daterangepicker from 'bootstrap-daterangepicker';
import '../lib/bootstrap-multiselect';
import 'microplugin';
import 'sifter';
import '../lib/selectize';


export default (function () {
  jQuery(document).ready(function($) {
    var rawDataExtractHelper = {
      dateRangeDTFormat: null,
      filters: {},

      init: function(dateRangeDTFormat, filters={}) {
        this.dateRangeDTFormat = dateRangeDTFormat;
        this.filters = filters;
      },

      dateRangeInit: function(input, ranges) {
        var self=this;
        $(input).daterangepicker({
          locale: {
            format: self.dateRangeDTFormat
          },
          startDate: moment(self.filters['start_date'], self.dateRangeDTFormat),
          endDate: moment(self.filters['end_date'], self.dateRangeDTFormat),
          "timePicker": true,
          "timePicker24Hour": true,
          "maxDate": moment().endOf('day'),
          "alwaysShowCalendars": true,
          "opens": "left",
          ranges: ranges
        }, function (start, end, label) {
          self.filters['start_date'] = start.format(self.dateRangeDTFormat);
          self.filters['end_date'] = end.format(self.dateRangeDTFormat);

          $(input).find("span").html(self.filters['start_date'] + " - " + self.filters['end_date']);
          $("input[name=start_date]").val(self.filters['start_date']);
          $("input[name=end_date]").val(self.filters['end_date']);
        });
      },

      dateSingleInit: function(input) {
        var self=this;
        $(input).daterangepicker({
          locale: {
            format: self.dateRangeDTFormat
          },
          startDate: moment(self.filters['start_date'], self.dateRangeDTFormat),
          "timePicker": true,
          "timePicker24Hour": true,
          "alwaysShowCalendars": true,
          "singleDatePicker": true,
          "opens": "left",
        }, function (start, end, label) {
          self.filters['start_date'] = start.format(self.dateRangeDTFormat);

          $(input).find("span").html(self.filters['start_date']);
          $("input[name=start_date]").val(self.filters['start_date']);
        });
      },

      multiSelectInit: function(input, filter_name, single=false) {
        var self=this;

        $(input).multiselect({
          enableCaseInsensitiveFiltering: false,
          includeSelectAllOption: false,
          numberDisplayed: 1,
          buttonWidth: '100%',
          onChange: function(element, checked) {
            if (!single) {
              var selectedOptions = $(input).val();
              $("input[name=" + filter_name + "]").val(selectedOptions.join(","));
            }
          }
        });

      },

      multiSelectizeInit: function(input, filter_name, single=false) {
        var self=this;

        var data_select_options = $(input).data('select-options');
        var select_options = null;

        if (data_select_options == 'groups') {
          select_options = gon.groups;
        } else if (data_select_options == 'sps') {
          select_options = gon.sps;
        }

        var options = {
          plugins: ['remove_button'],
          create: true,
          sortField: 'text',
          delimiter: ','
        }

        if (select_options) {
          options['options'] = select_options;
        }

        $(input).selectize(options);

        if (filter_name) {
          if (!single) {
            $(input).on('change', function() {
              var selectedOptions = $(input).val();
              $("input[name=" + filter_name + "]").val(selectedOptions.join(","));
            });
          }
        }

      },
      confirmQueryUser: function(path, clickButton, userFilterInput, modal) {
        var self = this;
        $(clickButton).on('click', function() {
          $.post(path, {single_field: true, user: {query_user_accepted: true}}, function (jdata) {
            if (!jdata || jdata['status'] != 'ok') {
              window.location.href = '/statistics/rawdataextract'
            } else {
              $(userFilterInput).attr("disabled", false);
              $(userFilterInput).parent().find("h5").find("a").remove();
              $(modal).modal('hide');
            }
          }, 'json').fail(function() {
            window.location.href = '/statistics/rawdataextract'
          });
        });
      },

      attributes_andor() {
        jQuery("#attributes_any").on('click', function() {
          jQuery("#attributes_any").addClass("active");
          jQuery("#attributes_all").removeClass("active");          
          jQuery("input[name=attributes_andor]").val("OR");
        });

        jQuery("#attributes_all").on('click', function() {
          jQuery("#attributes_all").addClass("active");
          jQuery("#attributes_any").removeClass("active");            
          jQuery("input[name=attributes_andor]").val("AND");
        });    
      },

      checkLargeQuery: function(form) {
        $(form).submit(function(e) {
          let orig_params = $(this).serializeArray();
          var params = $(this).serializeArray();

          for (var i=0; i<params.length; i++) {
            if (params[i]['name'] == 'format') {
              params[i]['value'] = 'json'
              break;
            }
          }

          let url_params = jQuery.param(params);
          let check_url = "/statistics/rawdataextract?" + url_params;

          let url_params_orig = jQuery.param(orig_params);
          let original_url = "/statistics/rawdataextract.csv?" + url_params_orig;

          e.preventDefault();

          $.getJSON(check_url, function(data) {
            if (!data || data['status'] != 'ok') {
              window.location.href=original_url;
            } else {
              if (data['data']['run_query_in_browser']) {
                window.location.href=original_url;
              } else {
                $("#confirmRunningInBgModal").modal('show');
              }
            }
          }).fail(function() {
            window.location.href=original_url;
          });
        });
      }
    }

    if (typeof gon != 'undefined') {
      var ranges = {}

      if (typeof gon.translations != 'undefined') {
        ranges[gon.translations.today] = [
          moment().startOf('day'),
          moment().endOf('day')
        ];
        ranges[gon.translations.yesterday] = [
          moment().subtract(1, 'days').startOf('day'),
          moment().subtract(1, 'days').endOf('day')
        ];
        ranges[gon.translations.last_7_days] = [
          moment().subtract(6, 'days').startOf('day'),
          moment()
        ];
        ranges[gon.translations.last_30_days] = [
          moment().subtract(29, 'days').startOf('day'),
          moment()
        ];
        ranges[gon.translations.this_month] = [
          moment().startOf('month'),
          moment().endOf('month')
        ];
        ranges[gon.translations.last_month] = [
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month')
        ];
        ranges[gon.translations.this_year] = [
          moment().startOf('year'),
          moment()
        ];
        ranges[gon.translations.last_year] = [
          moment().subtract(1, 'year').startOf('year'),
          moment().subtract(1, 'year').endOf('year')
        ];
      }

      if (gon.statisticsHelperPage && gon.statisticsHelperPage == "raw_data_extract") {
        rawDataExtractHelper.init(gon.date_format, gon.filters);
        rawDataExtractHelper.dateRangeInit($('#daterange'), ranges);
        rawDataExtractHelper.multiSelectizeInit($("#service-provider-select"), "service_providers");
        rawDataExtractHelper.multiSelectizeInit($("#authentication-type-select"), "auth_types");
        rawDataExtractHelper.multiSelectizeInit($("#attributes-select"), "attributes");
        rawDataExtractHelper.multiSelectizeInit($("#profiles-select"), "profiles");
        rawDataExtractHelper.multiSelectizeInit($("#groups-select"), "groups");
        rawDataExtractHelper.multiSelectizeInit($("#countries-select"), "country_codes");
        rawDataExtractHelper.multiSelectizeInit($("#filter-columns-select"), "filter_columns");
        rawDataExtractHelper.confirmQueryUser(gon.profile_path, $("#confirmQueryUser"), $("#users-filter"),$("#confirmQueryUserModal"));
        rawDataExtractHelper.attributes_andor();
        rawDataExtractHelper.checkLargeQuery($('form'));
      } else if (gon.statisticsHelperPage && gon.statisticsHelperPage == "new_scheduled_report") {
        rawDataExtractHelper.init(gon.date_format, gon.filters);
        rawDataExtractHelper.dateSingleInit($('#daterange'));
        rawDataExtractHelper.multiSelectizeInit($("#interval-select"), "interval", true);
        rawDataExtractHelper.multiSelectizeInit($("#service-provider-select"), "service_providers");
        rawDataExtractHelper.multiSelectizeInit($("#authentication-type-select"), "auth_types");
        rawDataExtractHelper.multiSelectizeInit($("#attributes-select"), "attributes");
        rawDataExtractHelper.multiSelectizeInit($("#profiles-select"), "profiles");
        rawDataExtractHelper.multiSelectizeInit($("#groups-select"), "groups");
        rawDataExtractHelper.multiSelectizeInit($("#countries-select"), "country_codes");
        rawDataExtractHelper.multiSelectizeInit($("#filter-columns-select"), "filter_columns");
        rawDataExtractHelper.confirmQueryUser(gon.profile_path, $("#confirmQueryUser"), $("#users-filter"),$("#confirmQueryUserModal"));
      }
    }
  });
}());
