import React from "react";
import fetchErrorHandler from '../../errors/fetchErrorHandler.js';
import fetchJsonResultHandler from '../../misc/fetchJsonResultHandler.js';
import readCookie from '../../misc/readCookie.js';
import Loading from '../../misc/Loading.jsx';
import RenderError from '../../misc/RenderError';
import RenderSuccess from '../../misc/RenderSuccess';
import { Link } from "react-router-dom";

class LogRetention extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      caughtError: '',
      loading: true,
      anonymizeLogs: null,
      purgeLogs: null,
      purgeDebugLogs: null,
      purgeGranularLogs: null
    }

    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type == 'checkbox' ? target.checked : target.value;
    const name = target.id 
    this.setState({
      [name] : value
    });
  }  

  async onSubmit(event) {
    event.preventDefault();
    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"))

    this.setState({
      loading: true
    });

    let formData = new FormData();
    formData.append('anonymize_logs', this.state.anonymizeLogs);
    formData.append("purge_logs", this.state.purgeLogs);
    formData.append("purge_debug_logs", this.state.purgeDebugLogs);
    formData.append("purge_granular_access_logs", this.state.purgeGranularLogs);
    formData.append('authenticity_token', csrf_token);

    try {
      let result = await fetch(
        '/api/internal/configuration/dataprivacy/logfile_retention',{
          method: 'POST',
          body: formData,
          credentials: 'same-origin'
        }
      );

      result = await fetchErrorHandler(result);
      const data = await fetchJsonResultHandler(result);
      if (data.status == "ok") {
        this.setState({
          loading: false,
          error_message: '',
          success_message: "The config has been successfully updated.",
        });
        let lbSyncDiv = document.getElementById('loadbalance_sync_warning');
        lbSyncDiv.style.display = '';            
      } else {
        this.setState({
          loading: false,
          error_message: data.message,
          success_message: ''
        });
      }
    } catch (error) {
      this.setState({caughtError: error});
    }    

  }

  async componentDidMount() {
    try {
      let result = await fetch(
        '/api/internal/configuration/dataprivacy/logfile_retention',
        {credentials: 'same-origin'}
      );

      result = await fetchErrorHandler(result);
      let data = await fetchJsonResultHandler(result);

      if (data.status == "ok") {
        this.setState({
          loading: false,
          anonymizeLogs: data.data.anonymize_logs,
          purgeLogs: data.data.purge_logs,
          purgeDebugLogs: data.data.purge_debug_logs,
          purgeGranularLogs: data.data.purge_granular_access_logs
        });            
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }

  render() {
    if (this.state.caughtError) {
      throw this.state.caughtError;
    }

    if (this.state.loading) {
      return (
        <div className="col-sm-12 react-loader">
          <Loading loading={this.state.loading} />
        </div>
      );
    }

    return (
      <div className="bgc-white bd bdrs-3 p-20 mB-20">
        <h5 className="c-grey-900 mB-20">Log file retention</h5>
  
        <div className="row">
          <div className="col-sm-12">
            <RenderError error_message={this.state.error_message} />
            <RenderSuccess success_message={this.state.success_message} />
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <label>
                  Anonymise personal data in log files 
                </label>

                <select id="anonymizeLogs"
                        value={this.state.anonymizeLogs}
                        onChange={this.handleChange}
                        className="form-control">
                  <option value="3_months">After 3 months</option>
                  <option value="6_months">After 6 months</option>
                  <option value="1_year">After 1 year</option>
                  <option value="2_years">After 2 years</option>
                  <option value="3_years">After 3 years</option>
                  <option value="4_years">After 4 years</option>
                  <option value="5_years">After 5 years</option>                  
                  <option value="10_years">After 10 years</option>
                  <option value="forever">Never</option>      
                </select>            
              </div>

              <p>
                Anonymising users will remove the ability to filter on the user names from the anonymised period.
              </p>              

              <div className="form-group">
                <label>Purge debug entries from log files</label>
                <select id="purgeDebugLogs"
                        value={this.state.purgeDebugLogs}
                        onChange={this.handleChange}
                        className="form-control">
                  <option value="1_day">After 1 day</option>
                  <option value="1_week">After 1 week</option>
                  <option value="1_month">After 1 month</option>
                  <option value="6_months">After 6 months</option>
                  <option value="1_year">After 1 year</option>
                  <option value="forever">Never</option>
                </select>
              </div>

              <div className="form-group">
                <label>Purge log files</label>
                <select id="purgeLogs"
                        value={this.state.purgeLogs}
                        onChange={this.handleChange}
                        className="form-control">
                  <option value="6_months">After 6 months</option>
                  <option value="1_year">After 1 year</option>
                  <option value="2_years">After 2 years</option>
                  <option value="3_years">After 3 years</option>
                  <option value="4_years">After 4 years</option>
                  <option value="5_years">After 5 years</option>
                  <option value="10_years">After 10 years</option>
                  <option value="forever">Never</option>
                </select>
              </div>

              <div className="form-group">
                <label>Purge granular access log files</label>
                <select id="purgeGranularLogs"
                        value={this.state.purgeGranularLogs}
                        onChange={this.handleChange}
                        className="form-control">
                  <option value="1_month">After 1 month</option>
                  <option value="3_months">After 3 months</option>
                  <option value="6_months">After 6 months</option>
                  <option value="1_year">After 1 year</option>
                  <option value="2_years">After 2 years</option>
                  <option value="3_years">After 3 years</option>
                  <option value="4_years">After 4 years</option>
                  <option value="5_years">After 5 years</option>
                  <option value="10_years">After 10 years</option>
                  <option value="forever">Never</option>
                </select>
              </div>
               <p>
                 <input type="submit" className="btn btn-primary" value="Save"/>    
               </p>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default LogRetention
