// Entry point for the build script in your package.json
/* eslint no-console:0 */

import '@babel/polyfill';

import 'jquery'
import 'bootstrap/dist/js/bootstrap';
import './lib/adminator/index';
import 'whatwg-fetch';
import './packs/bootstrap.scss';
import "react-diff-view/style/index.css";

if (window.msCrypto) {
  window.crypto = window.msCrypto;
}

import {App} from './index';

// Support component names relative to this directory:
var componentRequireContext = require.context("./components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

jQuery(document).ready(function($) {
  App($);
});
