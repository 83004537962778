import React from "react";
import { Link } from "react-router-dom";
import fetchErrorHandler from '../../errors/fetchErrorHandler.js';
import fetchJsonResultHandler from '../../misc/fetchJsonResultHandler.js';
import readCookie from '../../misc/readCookie.js';
import DataTable from '../../misc/DataTable';
import Modal from 'react-bootstrap/Modal';
import Loading from '../../misc/Loading.jsx';
import RenderError from '../../misc/RenderError';


class PredefinedRuleIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      caughtError: '',
      loading: false,
      selectedRows: [],
      showDeleteModal: false,
      error_message: ''
    }

    this.selectedRowCallback = this.selectedRowCallback.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteModalOpen = this.handleDeleteModalOpen.bind(this);
    this.handleDeleteModalClose = this.handleDeleteModalClose.bind(this);
    this.handleDeleteSelected = this.handleDeleteSelected.bind(this);    
  }

  selectedRowCallback(rows) {
    this.setState({
      selectedRows: rows
    });
  }

  handleDeleteModalOpen() {
    this.setState({
      showDeleteModal: true,
    });
  }

  handleDeleteModalClose() {
    this.setState({
      showDeleteModal: false,
    });
  }

  async handleDelete(event) {
    event.preventDefault();
  
    this.setState({
      loading: true,
      showDeleteModal: false
    });
    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"));

    let formData = new FormData();
    formData.append('authenticity_token', csrf_token)
    for (var key in this.state.selectedRows) {
      formData.append( 
        'pk[]', 
         this.state.selectedRows[key]
      );
    }

    try {
      let result = await fetch(
        '/configuration/granularaccess/predefined/delete.json',{
          method: 'POST',
          body: formData,
          credentials: 'same-origin'
        }
      );

      result = await fetchErrorHandler(result);
      const data = await fetchJsonResultHandler(result);
      if (data.status == "ok") {
        window.location.reload();
      } else {
        this.setState({
          loading: false,
          error_message: data.message,
        });
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }

  handleDeleteSelected(event) {
    event.preventDefault();

    this.setState({
      showDeleteModal: true
    });
  }

  renderName(column, text, row) {
    return (
      <Link to={"/predefined/edit/" + row['id']}>
        {text}
      </Link>
    );
  }

  renderRules(column, text, row) {
    let rules = [];
    if (column.data == 'allow_rules') {
      rules = row['allow_rules'];
    } else {
      rules = row['deny_rules'];
    }


    return (
      <ul>
        {rules.map(function(rule, ruleIndex) {
          return (
            <li key={ruleIndex}>
              <strong>{rule['idp_attribute']}:</strong> {rule['values']}
            </li>
          )
        })}
      </ul>
    )
  }

  render() {
    if (this.state.caughtError) {
      throw this.state.caughtError;
    }

    if (this.state.loading) {
      return (
        <div className="col-sm-12 react-loader">
          <Loading loading={this.state.loading} />
        </div>
      );
    }

    return (
      <div className="bgc-white bd bdrs-3 p-20 mB-20">
        <h5 className="c-grey-900 mB-20">Predefined condition sets</h5>
        <RenderError error_message={this.state.error_message} />      

        <div className="row">
          <div className="col-sm-12">
            <button className="btn btn-danger"
                    onClick={this.handleDeleteSelected}>
              Delete selected
            </button>&nbsp;
            <Link to='/predefined/new' className="btn btn-primary">
              Add new
            </Link>
          </div>
        </div>        

        <div className="row">
          <div className="col-sm-12">

            <DataTable
              api="/configuration/granularaccess/predefined.json"
              columns={[
                {
                  data: 'rule_name', text: "Name", sorting: true,
                  render_callback: this.renderName
                },
                {
                  data: 'allow_rules', text: "Allow rules", sorting: false,
                  render_callback: this.renderRules
                },
                {
                  data: 'deny_rules', text: "Deny rules", sorting: false,
                  render_callback: this.renderRules
                },
                {
                  data: 'comments', text: "Comments", sorting: false
                }
              ]}
              loadingCallback={this.loadingCallback}
              loadedCallback={this.loadedCallback}
              selectedRowCallback={this.selectedRowCallback}
              default_order_col="rule_name"
              default_order_dir="asc"
              row_select={true}
              per_page={10} />

            <Link to="/" className="btn btn-default">
              Go back
            </Link>     

            <Modal show={this.state.showDeleteModal} 
                   onHide={this.handleDeleteModalClose}>
              <Modal.Header closeButton>
                <Modal.Title>Predefined sets</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                Are you sure you want to delete the selected rules?
              </Modal.Body>

              <Modal.Footer>
                <button className="btn btn-primary"
                        onClick={this.handleDeleteModalClose}>Close</button>
                <button className="btn btn-danger"
                        onClick={this.handleDelete}>Delete</button>
              </Modal.Footer>
            </Modal>                     
          </div>
        </div>
      </div>
    )
  }
}

export default PredefinedRuleIndex
