import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { 
  IsActiveNavigationItem, GetSubMenuNavigationItems 
} from "./NavigationHelpers";
import ItemLink from "./ItemLink";
import { NavigationItems } from "./NavigationItems";


class SubNavigation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navList: []
    }
  }

  async componentDidMount() {
    this.setState({
      navList: GetSubMenuNavigationItems(await NavigationItems())
    });
  }  

  renderTopLevelItem(item, itemIndex) {
    let activeClass = ""; 

    let hasSubItems = false;

    if (
     typeof item['subItems'] !== 'undefined' &&
     Array.isArray(item['subItems']) &&
     item['subItems'].length > 0
    ) {
      activeClass = activeClass + " has-sub-items";
      hasSubItems = true
    }

    if (
      IsActiveNavigationItem(item)
    ) {
      activeClass = activeClass + " active";
    }

    return (
      <li className={activeClass} key={itemIndex}>
        <ItemLink item={item}>
          <div className="ico"><i className={item['ico']}/></div>
          <div className="label">{item['label']}</div>
          <div className="clear" />
        </ItemLink>

        {this.renderSubItems(item)}
      </li>
    );
  }

  renderSubItems(item) {
    if (
     typeof item['subItems'] === 'undefined' || 
     !Array.isArray(item['subItems']) || 
     item['subItems'].length < 0
    ) {
      return;
    }

    return (
      <div className="sub-menu-mobile">
        <ul className="sub-navigation">
          {item['subItems'].map(this.renderSubItem.bind(this))}
        </ul>
      </div>
    )
  }

  renderSubItem(item, itemIndex) {
    let activeClass = ""; 

    if (
     typeof item['subItems'] !== 'undefined' &&
     Array.isArray(item['subItems']) &&
     item['subItems'].length > 0
    ) {
      activeClass = activeClass + " has-sub-items";
    }

    if (IsActiveNavigationItem(item)) {
      activeClass = activeClass + " active";
    }

    return (
      <li className={activeClass} key={itemIndex}>
        <ItemLink item={item}>
          <div className="ico"><i className={item['ico']}/></div>
          <div className="label">{item['label']}</div>
          <div className="clear" />
        </ItemLink>

        {this.renderSubItems(item)}        
      </li>
    )
  }

  render() {
    return (
      <ul className="sub-navigation">
        {this.state.navList.map(this.renderTopLevelItem.bind(this))}
      </ul>
    )
  }
}

export default SubNavigation
