import React from "react";
import { Link } from "react-router-dom";
import fetchErrorHandler from '../../errors/fetchErrorHandler.js';
import fetchJsonResultHandler from '../../misc/fetchJsonResultHandler.js';
import DataTable from '../../misc/DataTable';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Loading from '../../misc/Loading.jsx';
import RenderError from '../../misc/RenderError';
import styled from 'styled-components';

const RightAlignedDiv = styled.div`
  text-align: right
`

class GranularAccessRuleIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      caughtError: '',
      loading: true,
      selectedRows: [],
      showDeleteModal: false,
      showConfigModal: false,
      defaultAction: 'allow',
      defaultActionTmp: 'allow',
      error_message: '',
    }

    this.loadedCallback = this.loadedCallback.bind(this);
  }

  loadedCallback() {
    this.setState({
      loading: false
    });
  }

  async componentDidMount() {
    try {
      let result = await fetch(
        '/api/internal/configuration/readonly/granularaccess/config',
        {credentials: 'same-origin'}
      );

      result = await fetchErrorHandler(result);
      let data = await fetchJsonResultHandler(result);

      if (data.status == "ok") {   
        this.setState({
          loading: false,
          defaultAction: data.data.default_action,
          defaultActionTmp: data.data.default_action
        });
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }  

  renderName(column, text, row) {
    return (
      <Link to={"/view/" + row['id']}>
        {text}
      </Link>
    );
  }

  renderEntityId(column, text, row) {
    if (text.length < 90) {
      return (<React.Fragment>{text}</React.Fragment>);
    }

    return text.substr(0, 87) + "...";
  }

  renderRules(column, text, row) {
    let rules = [];
    if (column.data == 'allow_rules') {
      rules = row['allow_rules'];
    } else {
      rules = row['deny_rules'];
    }


    return (
      <ul>
        {rules.map(function(rule, ruleIndex) {
          return (
            <li key={ruleIndex}>
              <strong>{rule['idp_attribute']}:</strong> {rule['values']}
            </li>
          )
        })}
      </ul>
    )
  }

  renderDefaultAction() {
    let action = "Allow access, bypassing multi-factor authentication";

    if (this.state.defaultAction == "allow_with_mfa") {
      action = "Allow access, using multi-factor authentication if " + 
               "the user has any MFA tokens";
    } else if (this.state.defaultAction == "allow_require_mfa") {
      action = "Allow access, requiring multi-factor authentication" + 
               " even if the user has no MFA tokens";
    } else if (this.state.defaultAction == "deny") {
      action = "Deny";
    }

    return (
      <React.Fragment>
        <strong>Default action:</strong> {action} 
      </React.Fragment>
    )
  }

  renderDefaultActionMFAOptions() {
    if (!gon.mfa_integrated) {
      return <React.Fragment />
     }

    return (
      <React.Fragment>
        <option value="allow_with_mfa">
          Allow access, using multi-factor authentication 
          if the user has any MFA tokens
        </option>
        <option value="allow_require_mfa">
          Allow access, requiring multi-factor authentication 
          even if the user has no MFA tokens
        </option>
      </React.Fragment>
    )
  }

  renderDuplicateRuleTooltip(props) {
    return(
      <Tooltip {...props}>
        Duplicate
      </Tooltip>
    )
  }

  renderDuplicateRule(column, text, row) {
    return (
      <React.Fragment>
        <OverlayTrigger
          placement="top"
          overlay={this.renderDuplicateRuleTooltip}
        >
          <i onClick={(e) => {
            this.handleRuleDuplicate(row.id)
          }} className="fa fa-copy rule-copy"/>
        </OverlayTrigger>
      </React.Fragment>
    )
  }

  render() {
    if (this.state.caughtError) {
      throw this.state.caughtError;
    }

    if (this.state.loading) {
      return (
        <div className="col-sm-12 react-loader">
          <Loading loading={this.state.loading} />
        </div>
      );
    }

    return (
      <div className="bgc-white bd bdrs-3 p-20 mB-20">
        <h5 className="c-grey-900 mB-20">Granular access</h5>
        <RenderError error_message={this.state.error_message} />      
              <div className="row">
          <div className="col-sm-12">
            <p>
              {this.renderDefaultAction()}
            </p>
            <DataTable
              api="/api/internal/configuration/readonly/granularaccess.json"
              columns={[
                {
                  data: 'rule_name', text: "Name", sorting: false,
                  render_callback: this.renderName
                },
                {
                  data: 'entity_id', text: "Entity id", 
                  render_callback: this.renderEntityId.bind(this)
                },
                {
                  data: 'allow_rules', text: "Allow rules", sorting: false,
                  render_callback: this.renderRules
                },
                {
                  data: 'deny_rules', text: "Deny rules", sorting: false,
                  render_callback: this.renderRules
                },
                {
                  data: 'comments', text: "Comments", sorting: false
                },
              ]}

              loadedCallback={this.loadedCallback}
              selectedRowCallback={this.selectedRowCallback}
              default_order_col="sort_order"
              default_order_dir="asc"
              row_select={false}
              per_page={100}
              enableRowDragDrop={false}
              rowDragDropIdCol="id"
              enableRowEnableDisable={false}
            
               />
     


 
          </div>
        </div>
      </div>
    )
  }
}

export default GranularAccessRuleIndex
