import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { IsActiveNavigationItem } from "./NavigationHelpers";
import { NavigationItems } from "./NavigationItems";
import ItemLink from "./ItemLink";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

class Navigation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navList: []
    }
  }

  async componentDidMount() {
  
    this.setState({
      navList: await NavigationItems()
    });
  }

  renderTopLevelItem(item, itemIndex) {
    let activeClass = ""; 

    let hasSubItems = false;

    if (
     typeof item['subItems'] !== 'undefined' &&
     Array.isArray(item['subItems']) &&
     item['subItems'].length > 0
    ) {
      activeClass = activeClass + " has-sub-items";
      hasSubItems = true;
    }

    if (IsActiveNavigationItem(item)) {
      activeClass = activeClass + " active";

      if (hasSubItems) {
        let layoutClass = document.getElementsByClassName("layout")[0];
        layoutClass.classList.add("sub-menu-active");
      }

    }

    let layoutClass = document.getElementsByClassName("layout")[0];
    layoutClass.classList.add("react-loaded");    

    return (
      <li className={activeClass} key={itemIndex}>
        <ItemLink item={item}>
          <OverlayTrigger key='right' placement='right' overlay ={
            <Tooltip id='tooltip-right'>{item['label']}</Tooltip>
          }>
            <div className="ico"><i className={item['ico']}/></div>
          </OverlayTrigger>
          <div className="label">{item['label']}</div>
          <div className="clear" />
        </ItemLink>

        {this.renderSubItems(item)}
      </li>
    );
  }

  renderSubItems(item) {
    if (
     typeof item['subItems'] === 'undefined' || 
     !Array.isArray(item['subItems']) || 
     item['subItems'].length < 0
    ) {
      return;
    }

    return (
      <div className="sub-menu-mobile">
        <ul className="sub-navigation">
          {item['subItems'].map(this.renderSubItem.bind(this))}
        </ul>
      </div>
    )
  }

  renderSubItem(item, itemIndex) {
    let activeClass = ""; 

    if (
     typeof item['subItems'] !== 'undefined' &&
     Array.isArray(item['subItems']) &&
     item['subItems'].length > 0
    ) {
      activeClass = activeClass + " has-sub-items";
    }

    if (IsActiveNavigationItem(item)) {
      activeClass = activeClass + " active";
    }

    return (
      <li className={activeClass} key={itemIndex}>
        <ItemLink item={item}>
          <OverlayTrigger key='right' placement='right' overlay ={
            <Tooltip id='tooltip-right'>{item['label']}</Tooltip>
          }>
            <div className="ico"><i className={item['ico']}/></div>
          </OverlayTrigger>
          <div className="label">{item['label']}</div>
          <div className="clear" />
        </ItemLink>

        {this.renderSubItems(item)}        
      </li>
    )
  }

  render() {   
    return (
      <ul className="navigation">
        {this.state.navList.map(this.renderTopLevelItem.bind(this))}
      </ul>
    )
  }
}

export default Navigation
