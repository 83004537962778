import React from "react"
import Loading from "../misc/Loading"
import RenderSuccess from "../misc/RenderSuccess"
import readCookie from '../misc/readCookie.js';
import {parseDiff, Diff} from 'react-diff-view';

const SyncIndex = () => {
  const [loading, setLoading] = React.useState(true);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [diffs, setDiffs] = React.useState({
    files: {
      file: [],
    },
    granularaccess: "",
    dashboard: ""
  });

  React.useEffect(() => {
    fetch("/api/internal/configuration/sync/get_diff", {
      method: "GET",
      credentials: "same-origin",
    }).then((response) => response.json())
      .then((data) => setDiffs(data))
      .then(() => setLoading(false));
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"));

    let formData = new FormData();
    formData.append('authenticity_token', csrf_token)

    fetch("/api/internal/configuration/sync", {
      method: "POST",
      credentials: "same-origin",
      body: formData
    }).then((response) => response.json())
      .then(() => {
        setLoading(false);
        setSuccessMessage("Changes synced successfully");
      });
  }

  const RenderChanges = (data) => {
    const diff = parseDiff(data)[0];

    return (
      <Diff viewType="split" diffType="unified" hunks={diff.hunks} />
    );
  }

  const RenderFile = (file, index) => {
    if (file.action === "deleting") {
      return (
        <h5 className="diff-header-delete" key={index}>{file.name}</h5>
      )
    } else if (file.action === "creating") {
      return (
        <h5 className="diff-header-create" key={index}>{file.name}</h5>
      )
    }

    const diff = parseDiff(file.diff)[0];
    return (
      <div className="card diff-card" key={index}>
        <h5 className={`card-header diff-header diff-${file.action}`}>
          <a className="collapsed d-block" data-toggle="collapse" href={`#diff-collapse-${index}`} aria-expanded="true" aria-controls={`diff-collapse-${index}`} id={`diff-heading-${index}`}>
            <i className="fa fa-chevron-down pull-right"></i>
            {file.name}
          </a>
        </h5>

        <div id={`diff-collapse-${index}`} className="collapse" aria-labelledby={`diff-heading-${index}`}  >
          <Diff viewType="split" diffType="unified" hunks={diff.hunks} />
        </div>
      </div>

    );
  }

  if (loading) {
    return (
      <div className="bgc-white bd bdrs-3 p-20 mB-20">
        <Loading loading={true} />
      </div>
    )
  }

  if (successMessage) {
    return (
      <div className="bgc-white bd bdrs-3 p-20 mB-20">
        <h5 className="c-grey-900 mB-20">Sync configuration</h5>
        <RenderSuccess success_message={successMessage} />
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className="bgc-white bd bdrs-3 p-20 mB-20">
        <h5 className="c-grey-900 mB-20">Sync configuration</h5>
        {(
          diffs.files.file.length == 0 && 
          diffs.granularaccess.length == 0 && 
          diffs.dashboard.length == 0
        ) ? ( 
          <p>There are currently no changes to be synced</p>
        ) : <p>Here is a list of changes that will be synced to all other nodes</p>}

        



        {(diffs.files && diffs.files.file.length > 0) ? (
          <React.Fragment>
            <h6>Files changed</h6>
            {diffs.files.file.map((file, index) => RenderFile(file,index))}
          </React.Fragment>
        ) : null}

        {(diffs.granularaccess && diffs.granularaccess.length > 0) ? (
          <div className="diff-view">
            <h6>Granular access changes</h6>
            {RenderChanges(diffs.granularaccess)}
          </div>
        ) : null}

        {(diffs.dashboard && diffs.dashboard.length > 0) ? (
          <div className="diff-view">
            <h6>Dashboard changes</h6>
            {RenderChanges(diffs.dashboard)}
          </div>
        ) : null}


        <form onSubmit={handleSubmit}>
          <input type="submit" value="Sync changes" className="btn btn-primary" />
        </form>
      </div>
    </React.Fragment>
  )
}

export default SyncIndex