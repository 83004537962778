import * as $ from 'jquery';
import moment from 'moment';
import daterangepicker from 'bootstrap-daterangepicker';
import '../../lib/bootstrap-multiselect';
import '../../lib/bootstrap4-editable';
import Chartkick from "chartkick";
import Chart from "chart.js";
import 'microplugin';
import 'sifter';
import '../../lib/selectize';
import HeatMap from '../heatmap';

class Statistics {

  constructor() {
    this.dateRangeDTFormat = null;
    this.filterURL = null;
    this.csvURL = null;
    this.filters = {};
    this.chartId = null;
    this.tableId = null;
    this.csvId = null;
    this.heatmap = null;
    this.granularity_id = null;
    this.compare_up = false;
    this.compare_down = false;
    this.top_10_enabled = false;

    Chartkick.addAdapter(Chart);
  }

  init(dateRangeDTFormat, filterURL=null, filters={}, csvURL=null, csvId=null, chartId=null, tableId=null) { 
    this.dateRangeDTFormat = dateRangeDTFormat;
    this.filterURL = filterURL
    this.filters = filters;
    this.chartId = chartId;
    this.tableId = tableId;
    this.csvURL = csvURL;
    this.csvId = csvId;

    if (csvId) {
      jQuery(csvId).attr("href", this.getFilterURL(this, true));
      jQuery("#extract_url").attr("href", this.getFilterURL(this, false, true));
    }
  }

  generateError(type, message) {
   jQuery(".container-fluid .js-errors").empty();
   jQuery(".container-fluid .js-errors").append("<div class=\"alert alert-"+type+"\">" + message + "</div>");    
  }

  getFilterURL(self, csv=false, raw_extract=false) {
    let filters = self.filters;

    if (self.compare_up) {
      filters['compare_up'] = true;
    } else  {
      delete filters['compare_up'];
    }

    if (self.compare_down) {
      filters['compare_down'] = true;
    } else {
      delete filters['compare_down'];
    }

    if (self.top_10_enabled) {
      filters['top_10_enabled'] = true;
    } else {
      delete filters['top_10_enabled'];
    }    

    let params = jQuery.param(filters);

    if (csv) {
      return self.csvURL + "?" + params;
    } else if (raw_extract) {
      return "/statistics/rawdataextract" + "?" + params;
    } else {
      return self.filterURL + "?" + params;
    }
  }

  updateData(self) {
    jQuery("div.loadedContent").addClass("loading");
    $.getJSON(self.getFilterURL(self), function(jData) {
      if (!jData) {
        window.location.href = self.getFilterURL(self).replace(".json", "");
      } else if (jData['status'] == 'ok') {
        var data = jData['data'];

        if (gon.statisticsHelperPage == "authentications" || gon.statisticsHelperPage == "resources") {
          if (self.chartId) {
            var chart = Chartkick.charts[self.chartId];
            chart.updateData(data['auth_graph_data']);
          }

          if (self.tableId) {
            var tableBody = jQuery(self.tableId).find("tbody")
            var tableData = data['auth_table_data'];
            var tableHead = jQuery(self.tableId).find("thead").first().find("tr");
            tableBody.empty();

            jQuery.each(tableData, function(date, values) {
              var tr = jQuery("<tr></tr>");
              tr.append("<td>" + date + "</td>");

              jQuery.each(values, function(key, value) {
                tr.append("<td>" + value + "</td>");
              });
              tableBody.append(tr);
            });

            // Find tr's that don't have the correct number of tds
            // this is because of our compare function, sometimes
            // the compared period doesn't have any results for the
            // series

            let expected_cols = data['auth_graph_data'].length;
            jQuery.each(jQuery(self.tableId).find("tbody").first().find("tr"), function(key, tr) {
              if (jQuery(tr).find("td").length < (expected_cols) + 1) {
                let toAdd = (expected_cols + 1) - jQuery(tr).find("td").length;
                for (var i=0; i < toAdd; i++) {
                  jQuery(tr).append("<td>0</td>");
                }
              }
            });

            var tableTotals = jQuery(self.tableId).find("#totals");

            if (tableTotals.length > 0) {
              var key_name = jQuery(tableTotals).find("th").first().text();
              jQuery(tableTotals).empty();
              jQuery(tableTotals).append("<th>" + key_name + "</th>");

              var tooltipHTML = " &nbsp; <a href=\"javascript:void(0);\" data-toggle=\"tooltip\" data-placement=\"top\" title=\"" + gon.translations['unique_users_tooltip'] + "\"><i class=\"fa fa-question-circle\"></i></a>";

              for(var i=0; i < data['auth_total_counts'].length; i++) {
                if (i == 1 && (typeof gon.failed_authentications === 'undefined' || !gon.failed_authentications)) {
                  data['auth_total_counts'][i] = data['auth_total_counts'][i] + tooltipHTML;
                }
                jQuery(tableTotals).append("<th>" + data['auth_total_counts'][i] + "</th>");
              };
            }


            jQuery(tableHead).find("th").not(':first').remove();
            let x=1;

            for(var i=0; i <  data['auth_graph_data'].length; i++) {
              // ok this is complicated, the graph series names
              // are shown in reverse order so basically if it's the first
              // in a two part series, grab the second one and if it's the
              // second one then grab the first one

              let y = JSON.parse(JSON.stringify(i));

              if (typeof gon.failed_authentications === 'undefined' || !gon.failed_authentications) {

                if (x == 1) {
                  y += 1
                  x += 1
                } else {
                  y -= 1
                  x = 1
                }
              }

              if (typeof data['auth_graph_data'][y] !== 'undefined') {
                jQuery(tableHead).append("<th>" + data['auth_graph_data'][y]['name'] + "</th>");
              }
            }
          }

          self.tooltipInit(jQuery("#totals a"));

          // Update attributes and or switcher
          if (self.filters['attributes_andor'] != 'OR') {
            jQuery("#attributes_all").addClass("active");
            jQuery("#attributes_any").removeClass("active");
          } else {
            jQuery("#attributes_any").addClass("active");
            jQuery("#attributes_all").removeClass("active");            
          }
        } else if (gon.statisticsHelperPage == "user_location") {
          var max = data['auth_max_count'];

          var map_data = data['auth_graph_data'].reduce(function(p, v) {
            p[v[0]] = v[3];
            return p;
          }, {});

          self.heatmap.update_map(map_data, max);


          var tableBody = jQuery("#data_table").find("tbody");
          tableBody.empty();

          for (var i=0; i <  data['auth_graph_data'].length; i++) {
            let country_key = data['auth_graph_data'][i][0];

            if (typeof gon.countries_alpha2_to_name  !== 'undefined') {
              if (typeof gon.countries_alpha2_to_name[country_key] !== 'undefined') {
                country_key = gon.countries_alpha2_to_name[country_key];
              }
            }

            var tr = jQuery("<tr></tr>");
            tr.append("<td>" +  country_key + "</td>");
            tr.append("<td>" +  data['auth_graph_data'][i][3] + "</td>");
            tableBody.append(tr);
          }
        }


        if (self.csvId) {
          jQuery(self.csvId).attr("href", self.getFilterURL(self, true));
          jQuery("#extract_url").attr("href", self.getFilterURL(self, false, true));
        }

        jQuery("div.loadedContent").removeClass("loading");

      } else {
        window.location.href = self.getFilterURL(self).replace(".json", "");
      }
    }).fail(function() {
      window.location.href = self.getFilterURL(self).replace(".json", "");
    });      
  }

  updateGranularity(self) {
    if (self.granularity_id) {
      let start_date = moment(self.filters['start_date'], self.dateRangeDTFormat);
      let end_date = moment(self.filters['end_date'], self.dateRangeDTFormat);

      let distance = moment.duration(end_date.diff(start_date));
      let distance_days = distance.asDays();
      let distance_hours = distance.asHours();

      if (distance_hours <= 3) {
        jQuery(self.granularity_id).find("option[value='minutes']").prop("disabled", false);
      } else {
        jQuery(self.granularity_id).find("option[value='minutes']").prop("disabled", true);

        if (self.filters['group_by'] == 'minutes') {
          self.filters['group_by'] = 'auto';
          jQuery(self.granularity_id).val('auto');
        }
      }

      if (distance_days <= 7) {
        jQuery(self.granularity_id).find("option[value='hour']").prop("disabled", false);
      } else {
        jQuery(self.granularity_id).find("option[value='hour']").prop("disabled", true);

        if (self.filters['group_by'] == 'hour') {
          self.filters['group_by'] = 'auto';
          jQuery(self.granularity_id).val('auto');
        }
      }

      if (distance_days <= 180) {
        jQuery(self.granularity_id).find("option[value='day']").prop("disabled", false);
      } else {
        jQuery(self.granularity_id).find("option[value='day']").prop("disabled", true);

        if (self.filters['group_by'] == 'day') {
          self.filters['group_by'] = 'auto';
          jQuery(self.granularity_id).val('auto');
        }
      }

      if (distance_days > 1) {
        jQuery(self.granularity_id).find("option[value='month']").prop("disabled", false);
      } else {
        jQuery(self.granularity_id).find("option[value='month']").prop("disabled", true);

        if (self.filters['group_by'] == 'month') {
          self.filters['group_by'] = 'auto';
          jQuery(self.granularity_id).val('auto');
        }
      }
    }

    return self;
  }  

  dateRangeInit(input, ranges) {
    var self=this;
    jQuery(input).daterangepicker({
      locale: {
        format: self.dateRangeDTFormat
      },
      startDate: moment(self.filters['start_date'], self.dateRangeDTFormat),
      endDate: moment(self.filters['end_date'], self.dateRangeDTFormat),
      "timePicker": true,
      "timePicker24Hour": true,
      "maxDate": moment().endOf('day'),
      "alwaysShowCalendars": true,
      "opens": "left",
      ranges: ranges
    }, function (start, end, label) {
      self.filters['start_date'] = start.format(self.dateRangeDTFormat);
      self.filters['end_date'] = end.format(self.dateRangeDTFormat);

      jQuery(input).find("span").html(self.filters['start_date'] + " - " + self.filters['end_date']);

      if (jQuery('#compare-up').length > 0) {
        let end_date = moment(self.filters['end_date'], self.dateRangeDTFormat);

        let new_end_date = end_date.add('1', 'day');

        if (moment().diff(new_end_date) < 0) {
          jQuery('#compare-up').hide();
        } else {
          jQuery('#compare-up').show();
        }
      }

      self = self.updateGranularity(self);
      self.updateData(self);
    });
  }

  multiSelectInit(input, filter_name) {
    var self=this;

    jQuery(input).multiselect({
      enableCaseInsensitiveFiltering: false,
      includeSelectAllOption: false,
      numberDisplayed: 1,
      buttonWidth: '100%',
      onChange: function(element, checked) {
        var selectedOptions = jQuery(input).val();
        self.filters[filter_name] = selectedOptions.join(",");
        self.updateData(self);
      }
    });
  }

  multiSelectizeInit(input, filter_name, one=false) {
    var self=this;


    var data_select_options = jQuery(input).data('select-options');
    var select_options = null;

    if (data_select_options == 'groups') {
      select_options = gon.groups;
    } else if (data_select_options == 'sps') {
      select_options = gon.sps;
    }

    var options = {
      create: true,
      sortField: 'text',
      delimiter: ',',
      plugins: ['remove_button']
    }

    if (select_options) {
      options['options'] = select_options;
    }

    jQuery(input).selectize(options);

    if (filter_name) {
      jQuery(input).on('change', function() {
        var selectedOptions = jQuery(input).val();
        self.filters[filter_name] = selectedOptions.join(",");
        self.updateData(self);
      });
    }
  }

  chartInit(data,type, chart_id=false) {

    if (!chart_id) {
      chart_id = this.chartId;
    }

    if (type == "AreaChart") {
      new Chartkick.AreaChart(chart_id, data);
    } else if (type=="BarChart") {
      new Chartkick.BarChart(chart_id, data, {
        library: {
          scales: {
            yAxes: [
            {
              ticks: {
                autoSkip: false
              }
            }]
          }
        }
      });
    }
  }

  confirmQueryUser(path, clickButton, userFilterInput, modal) {
    var self = this;
    jQuery(clickButton).on('click', function() {
      $.post(path, {single_field: true, user: {query_user_accepted: true}}, function (jdata) {
        if (!jdata || jdata['status'] != 'ok') {
          window.location.href = self.getFilterURL(self).replace(".json", "");
        } else {
          jQuery(userFilterInput).attr("disabled", false);
          jQuery(userFilterInput).parent().find("h5").find("a").remove();
          jQuery(modal).modal('hide');
        }
      }, 'json').fail(function() {
        window.location.href = self.getFilterURL(self).replace(".json", "");
      });
    });
  }

  textInputInit(input, filter_name) {
    var self = this;

    jQuery(input).keyup(function() {
      self.filters[filter_name] = jQuery(input).val();

      self.updateData(self);
    })
  }

  tooltipInit(input) {
    jQuery(input).tooltip();
  }

  heatmapInit() {

    var self=this;
    var max = gon.authentications_max;

    var map_data = gon.authentications.reduce(function(p, v) {
      p[v[0]] = v[3];
      return p;
    }, {});

    $.getJSON("/assets/json/world-countries-alpha-2.json", function(data) {
      self.heatmap = new HeatMap('map', data);
      self.heatmap.update_map(map_data, max);
    });

    jQuery("#unique_authentications").on('change', function() {
      if (jQuery("#unique_authentications").is(':checked')) {
        self.filters['unique'] = 'true';
      } else {
        self.filters['unique'] = 'false';
      }

      self.updateData(self);

    });
  }

  granularityInit(input) {
    this.granularity_id = input;
    var self=this;
    jQuery(input).on('change', function() {
      self.filters['group_by'] = jQuery(input + " option:selected").val();
      self.updateData(self);
    });

    this.updateGranularity(this);
  }

  attributes_andor() {
    var self=this;
    jQuery("#attributes_any").on('click', function() {
      self.filters['attributes_andor'] = 'OR';
      self.updateData(self);
    });

    jQuery("#attributes_all").on('click', function() {
      self.filters['attributes_andor'] = 'AND';
      self.updateData(self);

    });    
  }

  compareInit(input, direction) {
    var self = this;

    if (direction == 'up') {
      let end_date = moment(self.filters['end_date'], self.dateRangeDTFormat);

      let new_end_date = end_date.add('1', 'day');

      if (moment().diff(new_end_date) < 0) {
        jQuery(input).hide();
      } else {
        jQuery(input).show();
      }
    }

    jQuery(input).on('click', function() {
      if (direction == 'up') {
        if (self.compare_up) {
          self.compare_up = false;
          jQuery(input).addClass("badge-secondary");
          jQuery(input).removeClass("badge-primary");
        } else {
          self.compare_up = true;
          jQuery(input).addClass("badge-primary");
          jQuery(input).removeClass("badge-secondary");
        }

      } else {
        if (self.compare_down) {
          self.compare_down = false;
          jQuery(input).addClass("badge-secondary");
          jQuery(input).removeClass("badge-primary");
        } else {
          self.compare_down = true;
          jQuery(input).addClass("badge-primary");
          jQuery(input).removeClass("badge-secondary");
        }
      }

      self.updateData(self);
    });
  }
  top10Init(input) {
    var self = this;

    jQuery(input).on('click', function() {
      if (self.top_10_enabled) {
        self.top_10_enabled = false;
        jQuery(input).addClass("badge-secondary");
        jQuery(input).removeClass("badge-primary");
      } else {
        self.top_10_enabled = true;
        jQuery(input).addClass("badge-primary");
        jQuery(input).removeClass("badge-secondary");
      }

      self.updateData(self);
    });    
  }  
}

export default Statistics;
