import * as $ from 'jquery';
import 'datatables.net'
import '../../lib/datatables-bootstrap4';
import 'datatables.net-select';
import 'microplugin';
import 'sifter';
import 'jquery-highlight';
import '../../lib/datatables-searchHighlight';
import '../../lib/datatables-ellipsis';
import daterangepicker from 'bootstrap-daterangepicker';


class DiagnosticsLogViewer {
  constructor() {
    $.fn.dataTable.ext.errMode = function (settings, helpPage, message) {
      if (helpPage == "1" || helpPage == 1) {
        window.location.href = "/";
      }
    };    

    this.initTable();
  }

  initTable() {
    var columnDefs = [];
    var tail_log_file = "0";
    var last_refreshed = null;

    if (gon.datatablesEllipsisEnabled) {
      columnDefs = [
        {
            render: $.fn.dataTable.render.ellipsis(50),
            targets: 4
        }
      ];
    }

    var table = jQuery(gon.datatablesInput).DataTable({
      processing: true,
      serverSide: true,
      ajax: {
        url: jQuery(gon.datatablesInput).data('source'),
        data: function ( d ) {
          return $.extend( {}, d, {
            "date": jQuery('#start_date').val(),
            "log_file_selector": jQuery("#log_file_selector").val(),
            "tail_log_file": tail_log_file
          })
        },
      },
      pagingType: 'full_numbers',
      columns: gon.datatablesColumns,
      aaSorting: [],
      searchHighlight: true,
      columnDefs: columnDefs,
      "drawCallback": function( settings ) {
        jQuery(".short_tooltip").tooltip();
      },
    });

    table.on('xhr', function () {
      var json = table.ajax.json();
      if (json['last_refreshed']) {
        last_refreshed = json['last_refreshed'];
      }
    });

    jQuery("#daterange").daterangepicker({
      locale: {
        format: gon.date_format
      },
      startDate: moment(gon.filters['start_date'], gon.date_format),
      endDate: moment(gon.filters['end_date'], gon.date_format),
      "maxDate": moment().endOf('day'),
      "alwaysShowCalendars": true,
      "opens": "left",
      singleDatePicker: true,
    }, function (start, end, label) {
      var start_date = start.format(gon.date_format);
      var end_date = end.format(gon.date_format);

      jQuery("#daterange").find("span").html(start_date + " - " + end_date);
      jQuery("#start_date").val(start_date);
      jQuery("#end_date").val(end_date);

      if (tail_log_file == "1") {
        jQuery(".dataTables_filter input").prop('disabled', false);
        tail_log_file = "0";
        jQuery("#tail_log").show();
        jQuery("#tail_log_stop").hide();
      }

      table.draw();
    });


    jQuery("#log_file_selector").on('change', function() {
      window.location.href = gon.datatablesLogviewerPath + "?log_file_selector=" + jQuery("#log_file_selector").val() + "&date="+jQuery('#start_date').val();
    });

    var timer = null;

    function tailTimerFunction () {
      if (!last_refreshed) {
        return;
      }

      $.getJSON(gon.datatablesLogviewerPath +  "?log_file_selector=" + jQuery("#log_file_selector").val() + "&tail_modified=" + last_refreshed, function(data) {
        if (data['status'] == "ok") {
          if (last_refreshed != data['last_refreshed']) {
            last_refreshed = data['last_refreshed'];
            table.draw();
          }
        } else {
          window.location.href = gon.datatablesLogviewerPath;
        }
      }).fail(function() {
        window.location.href = gon.datatablesLogviewerPath;
      });
    };

    jQuery("#tail_log").on('click', function() {
      tail_log_file = "1";
      var start_date = moment().startOf('day').format(gon.date_format);
      var end_date = moment().endOf('day').format(gon.date_format);
      jQuery("#start_date").val(start_date);
      jQuery("#end_date").val(end_date);
      jQuery("#daterange").find("span").html(start_date + " - " + end_date);
      jQuery("#daterange").data('daterangepicker').setStartDate(start_date);
      jQuery("#daterange").data('daterangepicker').setEndDate(start_date);

      table.search("");
      jQuery(".dataTables_filter input").prop('disabled', true);
      jQuery("#tail_log").hide();
      jQuery("#tail_log_stop").show();

      table.draw();

      timer = setInterval(tailTimerFunction, 5000);
    });

    jQuery("#tail_log_stop").on('click', function() {
      tail_log_file = "0";
      jQuery(".dataTables_filter input").prop('disabled', false);

      jQuery("#tail_log").show();
      jQuery("#tail_log_stop").hide();
      if (timer) {
        clearTimeout(timer);
      }
      table.draw();

    });

    if (gon.tail_log !== undefined && gon.tail_log) {
      tail_log_file = "1";
      var start_date = moment().startOf('day').format(gon.date_format);
      var end_date = moment().endOf('day').format(gon.date_format);
      jQuery("#start_date").val(start_date);
      jQuery("#end_date").val(end_date);
      jQuery("#daterange").find("span").html(start_date + " - " + end_date);
      jQuery("#daterange").data('daterangepicker').setStartDate(start_date);
      jQuery("#daterange").data('daterangepicker').setEndDate(start_date);

      table.search("");
      jQuery(".dataTables_filter input").prop('disabled', true);
      jQuery("#tail_log").hide();
      jQuery("#tail_log_stop").show();

      table.draw();

      timer = setInterval(tailTimerFunction, 5000);
    }
  }
}

export default DiagnosticsLogViewer
