import * as $ from 'jquery';
import 'datatables.net'
import '../../lib/datatables-bootstrap4';
import 'datatables.net-select';
import 'microplugin';
import 'sifter';

class DashboardLogs {
  constructor() {
    $.fn.dataTable.ext.errMode = function (settings, helpPage, message) {
      if (helpPage == "1" || helpPage == 1) {
        window.location.href = "/";
      }
    };    

    this.initTable();
  }

  initTable() {
    jQuery(gon.datatablesInput).dataTable({
      processing: true,
      serverSide: true,
      ajax: jQuery(gon.datatablesInput).data('source'),
      pagingType: 'full_numbers',
      columns: gon.datatablesColumns,
      aaSorting: [[0, 'desc']],
      order: [[0, 'desc']]
    });
  }
};

export default DashboardLogs;
