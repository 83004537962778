import React from "react";
import fetchErrorHandler from '../../errors/fetchErrorHandler.js';
import fetchJsonResultHandler from '../../misc/fetchJsonResultHandler.js';
import GranularAccessFormCommon from './FormCommon';

class GranularAccessEditRule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      caughtError: '',
      loading: true,
      allow_attribute_boxes: 0,
      deny_attribute_boxes: 0,
      predefined_boxes: 0,
      attributeOptions: [],
      entityIds: [],
      enttiyIdSelect: null,
      sps: [],
      attributeSelectValues: {
        allow: {},
        deny: {}
      },
      attributeInputValues: {
        allow: {},
        deny: {},
        predefined: {},
      },
      attributeRegexInputValues: {
        allow: {},
        deny: {},
      },       
      attributeMultiValueInputValues: {
        allow: {},
        deny: {}
      },      
      predefined_rules: [],
      predefined_values: {
        predefined_allow: {},
        predefined_deny: {}
      },
      rule_name: '',
      comments: '',
      error_message: '',
      success_message: '',
      allow_radio: 'AND',
      deny_radio: 'AND',
      mfaStepUp: 'allow',
      defaultAction: 'allow',
    }
  }
  async componentDidMount() {
    try {
      let result = await fetch(
        '/api/internal/configuration/readonly/granularaccess/' + 
         this.props.match.params.rule_id, 
        {credentials: 'same-origin'}
      );

      result = await fetchErrorHandler(result);
      let data = await fetchJsonResultHandler(result);

      if (data.status == "ok") {
        let predefinedRules = data.data.predefined_rules;
        let attributeOptions = data.data.attributes.map(x => {
            return {'value': x, 'label': x}
          }
        );
   

        this.setState({
          loading: false,
          attributeOptions: attributeOptions,
          predefined_rules: predefinedRules,
          entityIds: data.data.sps,
          attributeSelectValues: data.data.attribute_select_values,
          attributeInputValues: data.data.attribute_input_values,
          attributeRegexInputValues: data.data.attribute_input_regex_values,
          attributeMultiValueInputValues: data.data.attribute_input_multi_values,
          allow_attribute_boxes: data.data.attribute_input_values.allow.length,
          deny_attribute_boxes: data.data.attribute_input_values.deny.length,
          predefined_boxes: data.data.attribute_input_values.predefined.length,
          predefined_values: data.data.predefined_values,
          rule_name: data.data.rule_name,
          comments: data.data.comments,
          enttiyIdSelect: data.data.entity_ids,
          sps: data.data.entity_ids.map(function(x, index) {
            return x['value'];
          }),
          allow_radio: data.data.allow_radio,
          deny_radio: data.data.deny_radio,
          mfaStepUp: data.data.mfa_stepup,
          defaultAction: data.data.default_action
        });
      }
      
    } catch (error) {
      this.setState({caughtError: error});
    }
  }

  setUpperState(state) {
    this.setState(state);
  }

  render() {
    return (
      <div className="bgc-white bd bdrs-3 p-20 mB-20">
        <h4 className="c-grey-900 mB-20">View granular access rule</h4>
        <GranularAccessFormCommon
            formState={this.state}
            setUpperState={this.setUpperState.bind(this)}
          />
      </div>
    )
  }
}

export default GranularAccessEditRule
