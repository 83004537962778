import React from "react";
import { Link } from "react-router-dom";
import fetchErrorHandler from '../errors/fetchErrorHandler.js';
import fetchJsonResultHandler from '../misc/fetchJsonResultHandler.js';
import readCookie from '../misc/readCookie.js';
import DataTable from '../misc/DataTable';
import Modal from 'react-bootstrap/Modal';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Loading from '../misc/Loading.jsx';
import RenderError from '../misc/RenderError';
import styled from 'styled-components';

const RightAlignedDiv = styled.div`
  text-align: right
`

class GranularAccessRuleIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      caughtError: '',
      loading: true,
      selectedRows: [],
      showDeleteModal: false,
      showConfigModal: false,
      defaultAction: 'allow',
      defaultActionTmp: 'allow',
      error_message: '',
    }

    this.loadedCallback = this.loadedCallback.bind(this);
    this.selectedRowCallback = this.selectedRowCallback.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteModalOpen = this.handleDeleteModalOpen.bind(this);
    this.handleDeleteModalClose = this.handleDeleteModalClose.bind(this);
    this.handleDeleteSelected = this.handleDeleteSelected.bind(this);   
    this.handleEnableDisable = this.handleEnableDisable.bind(this); 
    this.handlePositionUpdate = this.handlePositionUpdate.bind(this);
    this.handleConfigModalOpen = this.handleConfigModalOpen.bind(this);
    this.handleConfigModalClose = this.handleConfigModalClose.bind(this);
    this.handleDefaultActionChange = this.handleDefaultActionChange.bind(this);
    this.handleConfigUpdate = this.handleConfigUpdate.bind(this);
    this.handleRuleDuplicate = this.handleRuleDuplicate.bind(this);
    this.renderDuplicateRule = this.renderDuplicateRule.bind(this);
  }

  selectedRowCallback(rows) {
    this.setState({
      selectedRows: rows
    });
  }

  handleDeleteModalOpen() {
    this.setState({
      showDeleteModal: true,
    });
  }

  handleDeleteModalClose() {
    this.setState({
      showDeleteModal: false,
    });
  }

  handleConfigModalOpen() {
    this.setState({
      showConfigModal: true,
      defaultActionTmp: this.state.defaultAction
    });
  }

  handleConfigModalClose() {
    this.setState({
      showConfigModal: false,
      defaultActionTmp: this.state.defaultAction
    });
  }

  loadedCallback() {
    this.setState({
      loading: false
    });
  }

  async handleDelete(event) {
    event.preventDefault();
  
    this.setState({
      loading: true,
      showDeleteModal: false
    });
    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"));

    let formData = new FormData();
    formData.append('authenticity_token', csrf_token)
    for (var key in this.state.selectedRows) {
      formData.append( 
        'pk[]', 
         this.state.selectedRows[key]
      );
    }

    try {
      let result = await fetch(
        '/api/internal/configuration/granularaccess',{
          method: 'DELETE',
          body: formData,
          credentials: 'same-origin'
        }
      );

      result = await fetchErrorHandler(result);
      const data = await fetchJsonResultHandler(result);
      if (data.status == "ok") {
        window.location.reload();
      } else {
        this.setState({
          loading: false,
          error_message: data.message,
        });
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }

  async handleEnableDisable(row, row_enabled) {
    if (row_enabled) {
      row_enabled = false;
    } else {
      row_enabled = true;
    }

    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"));

    let formData = new FormData();
    formData.append('pk', row['id']);
    formData.append('field', 'enabled');
    formData.append('value', row_enabled);
    formData.append('authenticity_token', csrf_token);

    try {
      let result = await fetch(
        '/api/internal/configuration/granularaccess',{
          method: 'PATCH',
          body: formData,
          credentials: 'same-origin'
        }
      );

      result = await fetchErrorHandler(result);
      const data = await fetchJsonResultHandler(result);
      if (data.status != "ok") {
        this.setState({
          loading: false,
          error_message: data.message,
        });        
      } else {
        let lbSyncDiv = document.getElementById('loadbalance_sync_warning');
        lbSyncDiv.style.display = '';        
        return true;
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }

  async handlePositionUpdate(rows) {
    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"));
    let formData = new FormData();
    for (let key in rows) {
      formData.append('pk[]', rows[key]['id']);
      formData.append('position[]', key);
    }
    formData.append('authenticity_token', csrf_token);


    try {
      let result = await fetch(
        '/api/internal/configuration/granularaccess/sort',{
          method: 'PATCH',
          body: formData,
          credentials: 'same-origin'
        }
      );

      result = await fetchErrorHandler(result);
      const data = await fetchJsonResultHandler(result);
      if (data.status != "ok") {
        this.setState({
          loading: false,
          error_message: data.message,
        });        
      } else {
        let lbSyncDiv = document.getElementById('loadbalance_sync_warning');
        lbSyncDiv.style.display = '';        
        return true;
      }      
    } catch (error) {
      this.setState({caughtError: error});
    }
  }

  async handleConfigUpdate(event) {
    event.preventDefault();
  
    this.setState({
      loading: true,
      showConfigModal: false,
      defaultAction: this.state.defaultActionTmp
    });

    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"));

    let formData = new FormData();
    formData.append('authenticity_token', csrf_token);
    formData.append('default_action', this.state.defaultActionTmp);

    try {
      let result = await fetch(
        '/api/internal/configuration/granularaccess/config',{
          method: 'PATCH',
          body: formData,
          credentials: 'same-origin'
        }
      );

      result = await fetchErrorHandler(result);
      const data = await fetchJsonResultHandler(result);
      if (data.status == "ok") {
        window.location.reload();
      } else {
        this.setState({
          loading: false,
          error_message: data.message,
        });
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }  

  async handleRuleDuplicate(id) {
    this.setState({
      loading: true,
    });

    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"));

    let formData = new FormData();
    formData.append('authenticity_token', csrf_token);
    formData.append('pk', id);

    try {
      let result = await fetch(
        '/api/internal/configuration/granularaccess/duplicate',{
          method: 'PATCH',
          body: formData,
          credentials: 'same-origin'
        }
      );

      result = await fetchErrorHandler(result);
      const data = await fetchJsonResultHandler(result);
      if (data.status == "ok") {
        this.props.history.push('edit/' + data.data.new_rule.id);
      } else {
        this.setState({
          loading: false,
          error_message: data.message,
        });
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }    

  handleDeleteSelected(event) {
    event.preventDefault();

    this.setState({
      showDeleteModal: true
    });
  }

  handleDefaultActionChange(event) {
    this.setState({
      defaultActionTmp: event.target.value
    });
  }

  async componentDidMount() {
    try {
      let result = await fetch(
        '/api/internal/configuration/granularaccess/config',
        {credentials: 'same-origin'}
      );

      result = await fetchErrorHandler(result);
      let data = await fetchJsonResultHandler(result);

      if (data.status == "ok") {   
        this.setState({
          loading: false,
          defaultAction: data.data.default_action,
          defaultActionTmp: data.data.default_action
        });
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }  

  renderName(column, text, row) {
    return (
      <Link to={"/edit/" + row['id']}>
        {text}
      </Link>
    );
  }

  renderEntityId(column, text, row) {
    if (text.length < 90) {
      return (<React.Fragment>{text}</React.Fragment>);
    }

    return text.substr(0, 87) + "...";
  }

  renderRules(column, text, row) {
    let rules = [];
    if (column.data == 'allow_rules') {
      rules = row['allow_rules'];
    } else {
      rules = row['deny_rules'];
    }


    return (
      <ul>
        {rules.map(function(rule, ruleIndex) {
          return (
            <li key={ruleIndex}>
              <strong>{rule['idp_attribute']}:</strong> {rule['values']}
            </li>
          )
        })}
      </ul>
    )
  }

  renderDefaultAction() {
    let action = "Allow access, bypassing multi-factor authentication";

    if (this.state.defaultAction == "allow_with_mfa") {
      action = "Allow access, using multi-factor authentication if " + 
               "the user has any MFA tokens";
    } else if (this.state.defaultAction == "allow_require_mfa") {
      action = "Allow access, requiring multi-factor authentication" + 
               " even if the user has no MFA tokens";
    } else if (this.state.defaultAction == "deny") {
      action = "Deny";
    }

    return (
      <React.Fragment>
        <strong>Default action:</strong> {action} 
      </React.Fragment>
    )
  }

  renderDefaultActionMFAOptions() {
    if (!gon.mfa_integrated) {
      return <React.Fragment />
     }

    return (
      <React.Fragment>
        <option value="allow_with_mfa">
          Allow access, using multi-factor authentication 
          if the user has any MFA tokens
        </option>
        <option value="allow_require_mfa">
          Allow access, requiring multi-factor authentication 
          even if the user has no MFA tokens
        </option>
      </React.Fragment>
    )
  }

  renderDuplicateRuleTooltip(props) {
    return(
      <Tooltip {...props}>
        Duplicate
      </Tooltip>
    )
  }

  renderDuplicateRule(column, text, row) {
    return (
      <React.Fragment>
        <OverlayTrigger
          placement="top"
          overlay={this.renderDuplicateRuleTooltip}
        >
          <i onClick={(e) => {
            this.handleRuleDuplicate(row.id)
          }} className="fa fa-copy rule-copy"/>
        </OverlayTrigger>
      </React.Fragment>
    )
  }

  render() {
    if (this.state.caughtError) {
      throw this.state.caughtError;
    }

    if (this.state.loading) {
      return (
        <div className="col-sm-12 react-loader">
          <Loading loading={this.state.loading} />
        </div>
      );
    }

    return (
      <div className="bgc-white bd bdrs-3 p-20 mB-20">
        <h5 className="c-grey-900 mB-20">Granular access</h5>
        <RenderError error_message={this.state.error_message} />      

        <div className="row">
          <div className="col-sm-6">
            <button className="btn btn-danger"
                    onClick={this.handleDeleteSelected}>
              Delete selected
            </button>&nbsp;
            <Link to='/new' className="btn btn-primary">Add new</Link>
          </div>

          <RightAlignedDiv className="col-sm-6">
            <Link to='/predefined' className="btn btn-primary">
              Predefined rules
             </Link>
          </RightAlignedDiv>
        </div>        
        <br />
        <div className="row">
          <div className="col-sm-12">
            <p>
              {this.renderDefaultAction()}
              &nbsp; 
              <a href="javascript:void(0);"
                 onClick={(e) => this.handleConfigModalOpen()}>(Change)</a>
            </p>
            <DataTable
              api="/api/internal/configuration/granularaccess.json"
              columns={[
                {
                  data: 'rule_name', text: "Name", sorting: false,
                  render_callback: this.renderName
                },
                {
                  data: 'entity_id', text: "Entity id", 
                  render_callback: this.renderEntityId.bind(this)
                },
                {
                  data: 'allow_rules', text: "Allow rules", sorting: false,
                  render_callback: this.renderRules
                },
                {
                  data: 'deny_rules', text: "Deny rules", sorting: false,
                  render_callback: this.renderRules
                },
                {
                  data: 'comments', text: "Comments", sorting: false
                },
                {
                  data: '', text: "", sorting: false, 
                  render_callback: this.renderDuplicateRule
                }
              ]}

              loadedCallback={this.loadedCallback}
              selectedRowCallback={this.selectedRowCallback}
              default_order_col="sort_order"
              default_order_dir="asc"
              row_select={true}
              per_page={100}
              enableRowDragDrop={true}
              rowDragDropIdCol="id"
              enableRowEnableDisable={true}
              onEnableDisableCallback={this.handleEnableDisable}
              onDragEndCallback={this.handlePositionUpdate} />
     

            <Modal show={this.state.showDeleteModal} 
                   onHide={this.handleDeleteModalClose}>
              <Modal.Header closeButton>
                <Modal.Title>Granular access</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                Are you sure you want to delete the selected rules?
              </Modal.Body>

              <Modal.Footer>
                <button className="btn btn-primary"
                        onClick={this.handleDeleteModalClose}>Close</button>
                <button className="btn btn-danger"
                        onClick={this.handleDelete}>Delete</button>
              </Modal.Footer>
            </Modal>       

            <Modal show={this.state.showConfigModal} 
                   onHide={this.handleConfigModalClose}>
              <Modal.Header closeButton>
                <Modal.Title>Granular access</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="form-group">
                  <label>Default action</label>
                  <select className="form-control"
                          onChange={this.handleDefaultActionChange}
                          value={this.state.defaultActionTmp}>
                    <option value="allow">
                      Allow access, bypassing multi-factor authentication
                    </option>
                    {this.renderDefaultActionMFAOptions()}
                    <option value="deny">
                      Deny
                    </option>
                  </select>
                 </div>
              </Modal.Body>

              <Modal.Footer>
                <button className="btn btn-secondary"
                        onClick={this.handleConfigModalClose}>Close</button>
                <button className="btn btn-primary"
                        onClick={this.handleConfigUpdate}>Confirm</button>
              </Modal.Footer>
            </Modal>                           
          </div>
        </div>
      </div>
    )
  }
}

export default GranularAccessRuleIndex
