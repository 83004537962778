import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import 'react-dates/initialize';


import IdmIndex from './Index';
import IdmNew from './New';
import IdmEdit from './Edit';
import IdmImport from './IdmImport';

import Error404 from '../errors/Error404';
import Error500 from '../errors/Error500';


class Idm extends React.Component {
  render() {
    return(
      <HashRouter>
        <Error500>
          <Switch>
            <Route exact path="/" component={IdmIndex} />
            <Route exact path="/new" component={IdmNew} />
            <Route exact path="/edit/:user_id" component={IdmEdit} />
            <Route exact path="/import" component={IdmImport} />
            <Route component={Error404} />
          </Switch>
        </Error500>
      </HashRouter>
    )
  }
}

export default Idm
