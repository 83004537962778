import React from "react";
import fetchErrorHandler from '../../errors/fetchErrorHandler.js';
import fetchJsonResultHandler from '../../misc/fetchJsonResultHandler.js';
import readCookie from '../../misc/readCookie.js';
import PredefinedFormCommon from './FormCommon';

class PredefinedRuleNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      caughtError: '',
      loading: true,
      allow_attribute_boxes: 0,
      deny_attribute_boxes: 0,
      attributeOptions: [],
      attributeSelectValues: {
        allow: {},
        deny: {}
      },
      attributeInputValues: {
        allow: {},
        deny: {},
      },
      attributeRegexInputValues: {
        allow: {},
        deny: {},
      },      
      attributeMultiValueInputValues: {
        allow: {},
        deny: {}
      },      
      rule_name: '',
      comments: '',
      error_message: ''
    }
 
  }

  async onSubmit(event) {
    event.preventDefault();
    const csrf_token = decodeURIComponent(readCookie("X-CSRF-Token"))

    this.setState({
      loading: true
    });

    let formData = new FormData();
    formData.append("rule_name", this.state.rule_name);
    formData.append("comments", this.state.comments);
    for (var key in this.state.attributeSelectValues.allow) {
      if (this.state.attributeSelectValues.allow[key] == null) {
        this.state.attributeSelectValues.allow[key] = {
          value: null
        }
      }
      formData.append( 
        'allow_attributes[]', 
         this.state.attributeSelectValues.allow[key].value
      );
    }
    for (var key in this.state.attributeInputValues.allow) {
      formData.append(
        'allow_values[]', 
        this.state.attributeInputValues.allow[key]
      );
    }

    for (var key in this.state.attributeRegexInputValues.allow) {
      let value = this.state.attributeRegexInputValues.allow[key];

      if (typeof value === 'object') {
        if (typeof value.value !== 'undefined') {
          value = value.value;
        }
      }

      formData.append(
        'allow_regex[]', 
        value  
      );
    } 

    for (var key in this.state.attributeMultiValueInputValues.allow) {
      let value = this.state.attributeMultiValueInputValues.allow[key];

      if (typeof value === 'object') {
        if (typeof value.value !== 'undefined') {
          value = value.value;
        }
      }

      formData.append(
        'allow_multi[]', 
        value  
      );
    }       

    for (var key in this.state.attributeSelectValues.deny) {
      if (this.state.attributeSelectValues.deny[key] == null) {
        this.state.attributeSelectValues.deny[key] = {
          value: null
        }
      }      
      formData.append( 
        'deny_attributes[]', 
        this.state.attributeSelectValues.deny[key].value
      );
    }
    for (var key in this.state.attributeInputValues.deny) {
      formData.append(
        'deny_values[]', 
        this.state.attributeInputValues.deny[key]
      );
    }    
    for (var key in this.state.attributeRegexInputValues.deny) {
      let value = this.state.attributeRegexInputValues.deny[key];

      if (typeof value === 'object') {
        if (typeof value.value !== 'undefined') {
          value = value.value;
        }
      }

      formData.append(
        'deny_regex[]', 
        value  
      );
    }   

    for (var key in this.state.attributeMultiValueInputValues.deny) {
      let value = this.state.attributeMultiValueInputValues.deny[key];

      if (typeof value === 'object') {
        if (typeof value.value !== 'undefined') {
          value = value.value;
        }
      }

      formData.append(
        'deny_multi[]', 
        value  
      );
    }

    formData.append('authenticity_token', csrf_token);

    try {
      let result = await fetch(
        '/configuration/granularaccess/predefined/new.json',{
          method: 'POST',
          body: formData,
          credentials: 'same-origin'
        }
      );

      result = await fetchErrorHandler(result);
      const data = await fetchJsonResultHandler(result);
      if (data.status == "ok") {
        let lbSyncDiv = document.getElementById('loadbalance_sync_warning');
        lbSyncDiv.style.display = '';          
        this.props.history.push("/predefined");
      } else {
        this.setState({
          loading: false,
          error_message: data.message,
        });
      }
    } catch (error) {
      this.setState({caughtError: error});
    }
  }

  async componentDidMount() {
    try {
      let result = await fetch(
        '/configuration/granularaccess/predefined/new.json', 
        {credentials: 'same-origin'}
      );

      result = await fetchErrorHandler(result);
      let data = await result.json();

      if (data.status == "ok") {
        this.setState({
          loading: false,
          attributeOptions: data.data.attributes.map(x => {
            return {'value': x, 'label': x}
          })
        });
      }
      
    } catch (error) {
      this.setState({caughtError: error});
    }
  }


  setUpperState(state) {
    this.setState(state);
  } 

  render() {
    return (
      <div className="bgc-white bd bdrs-3 p-20 mB-20">
        <h4 className="c-grey-900 mB-20">Add a new predefined condition set</h4>

        <PredefinedFormCommon
          formState={this.state}
          onSubmit={this.onSubmit.bind(this)}
          setUpperState={this.setUpperState.bind(this)}
        />
      </div>
    )  
  }
}

export default PredefinedRuleNew
