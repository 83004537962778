import React from "react";
import GranularAccessForm from './Form';

class FormCommon extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
    return (
      <GranularAccessForm 
        formState={this.props.formState}
      />
    )
  }
}

export default FormCommon
