import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { IsActiveNavigationItem } from "./NavigationHelpers";

class ItemLink extends React.Component {
  handleClick(event) {

  }

  renderReactLink() {
    return (
      <a href={this.props.item['link']}>
        {this.props.children}
      </a>
    )    
  }

  render() {
    if (this.props.item['link'] && 
        this.props.item['link'].length > 0
    ) {
      return this.renderReactLink();
    }

    return (
      <div className="no-link"
           onClick={this.handleClick.bind(this)}>
        {this.props.children}
      </div>
    )
  }
}

ItemLink.propTypes = {
  item: PropTypes.object.isRequired
}

export default ItemLink
