export default async function fetchJsonResultHandler(result) {
  var data = null;

  try {
    data = await result.json();
  } catch (error) {
    window.location.reload();
    return null;
  }

  if (data && typeof data.status === 'undefined') {
    return data;
  }

  if (data.status == "error" && typeof data.message === 'undefined') {
    return result;
  } else if (
    data.status == "error" && data.message == "You are not authenticated."
  ) {
    window.location.reload();
    return null;
  }


  return data;
}
