import React from "react";
import PropTypes from "prop-types";

class Error403 extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
    return (
      <div>
        <h1>Forbidden</h1>
        <p>You do not have access to view this page.</p>
        <p><a href="/">Click here to go back</a></p>
      </div>
    )
  }
}

export default Error403
