export const IsActiveNavigationItem = (
  navItem, incDropDown=true
) => {
  let pathName = window.location.pathname;
  let navLink = navItem['link'];
  let navUniqId = navItem['uniqId'];

  let layoutState = {
    droppedDownMenuEl: null
  };

  // Remove trailing slashes
  if (pathName.substr(-1) === '/') {
    pathName = pathName.substr(0, (pathName.length - 1));
  }

  if (navLink && navLink === pathName) {
    return true;
  }
  
  if (navLink && navLink != '/' && pathName.startsWith(navLink)) {
    return true;
  }

  if (
    incDropDown && layoutState && layoutState.droppedDownMenuEl === navUniqId
  ) {
    return true;
  }

  if (
    typeof navItem['subItems'] !== 'undefined' && 
    Array.isArray(navItem['subItems'])
  ) {

    if (!navItem['topLevel'] && layoutState && layoutState.activeDropped) {
      return false;
    }

    for(var i=0; i < navItem['subItems'].length; i++) {
      // Run this same function against sub items recursively 
      if (IsActiveNavigationItem(navItem['subItems'][i])) {
        return true;
      }
    }
  }

  return false;
}

export const GetSubMenuNavigationItems = (navList) => {
  if (typeof navList === 'undefined' || !Array.isArray(navList)) {
    return [];
  }

  let pathName = window.location.pathname;

  // Remove trailing slashes
  if (pathName.substr(-1) === '/') {
    pathName = pathName.substr(0, (pathName.length - 1));
  }

  for (var i=0; i < navList.length; i++) {
    let navItem = navList[i];

    if (IsActiveNavigationItem(navItem)) {
      if (
        typeof navItem['subItems'] !== 'undefined' && 
        Array.isArray(navItem['subItems'])
      ) {
        return navItem['subItems'];
      }

      return [];
    }
  }

  return [];
}

export const AddUniqueIdsToNavigationItems = (navList) => {
  if (typeof navList === 'undefined' || !Array.isArray(navList)) {
    return [];
  }  

  for (var i=0; i < navList.length; i++) {
    let uniqId = ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>(
      c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4
    ).toString(16));

    navList[i]['uniqId'] = uniqId;
    navList[i]['subItems'] = AddUniqueIdsToNavigationItems(
      navList[i]['subItems']
    );
  }

  return navList;
}

export const AddExtraInfoToNavigationItems = (navList) => {
  if (typeof navList === 'undefined' || !Array.isArray(navList)) {
    return [];
  }  

  navList = AddUniqueIdsToNavigationItems(navList); 

  for (var i=0; i < navList.length; i++) {
    navList[i]['topLevel'] = true;
  }

  return navList;
}
